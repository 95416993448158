import CosmicSevice from "../services/cosmicService";

export default class CosmicController {
	static async getPosts(settings) {
		const result = {
			success: false,
			message: "",
		};

		try {
			const response = await CosmicSevice.getPosts(settings);

			result.data = response;
			result.success = true;
			return result;
		} catch (e) {
			result.message = "Ошибка при получении данных";
			return result;
		}
	}

	static async getPostBySlug(slug) {
		const result = {
			success: false,
			message: ""
		}

		try {
			const response = await CosmicSevice.getPostBySlug(slug);

			result.data = response;
			result.success = true;
			return result;
		} catch (error) {
			result.message = `Ошибка при получении поста по slug "${slug}"`;
			return result
		}

	}
}
