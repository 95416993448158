import c from './styles.module.scss'
import { NavLink } from 'react-router-dom'

const Logo = () => {
    const svg = (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Слой_1" x="0px" y="0px" viewBox="0 0 872.2 283.6" style={{enableBackground: 'new 0 0 872.2 283.6'}}>
            <rect x="297.9" width="6.5" height="283.6"/>
            <g>
                <path d="M417.5,97.1c-12.3-1-18.5,4.3-18.5,15.9v2.1h18.5v11.7H399V185h-12.2v-58.2h-11.2v-11.7h11.2V113c0-9.4,2.6-16.6,7.9-21.4   c5.3-4.8,12.8-6.9,22.7-6.3v11.8H417.5z"/>
                <path d="M487.9,115.1h12.2V185h-12.2v-12c-6.1,9.2-14.9,13.8-26.4,13.8c-9.8,0-18.1-3.6-25-10.7c-6.9-7.1-10.3-15.8-10.3-26.1   s3.4-19,10.3-26.1c6.9-7.1,15.2-10.7,25-10.7c11.6,0,20.4,4.6,26.4,13.8V115.1z M463,175.1c7.1,0,13-2.4,17.8-7.2   s7.1-10.7,7.1-17.8s-2.4-13-7.1-17.8c-4.8-4.8-10.7-7.2-17.8-7.2c-7,0-12.9,2.4-17.6,7.2c-4.8,4.8-7.1,10.7-7.1,17.8   s2.4,13,7.1,17.8S456,175.1,463,175.1z"/>
                <path d="M558.4,186.9c-10.5,0-19.3-3.5-26.3-10.6c-7-7-10.5-15.8-10.5-26.2s3.5-19.2,10.5-26.2s15.8-10.6,26.3-10.6   c6.9,0,13.1,1.7,18.6,5s9.6,7.8,12.3,13.4l-10.2,5.9c-1.8-3.7-4.5-6.7-8.2-9c-3.7-2.2-7.9-3.4-12.5-3.4c-7,0-12.8,2.4-17.6,7.1   c-4.7,4.8-7.1,10.7-7.1,17.8c0,7,2.4,12.9,7.1,17.6s10.6,7.1,17.6,7.1c4.7,0,8.9-1.1,12.6-3.3c3.7-2.2,6.6-5.2,8.5-8.9l10.3,6   c-3,5.5-7.3,9.9-12.9,13.2C571.4,185.2,565.2,186.9,558.4,186.9z"/>
                <path d="M646.9,126.9h-18.6v37.9c0,3.4,0.7,5.9,2,7.3c1.4,1.4,3.4,2.2,6.2,2.2s6.2,0,10.3-0.2V185c-10.6,1.4-18.4,0.5-23.4-2.6   c-4.9-3.1-7.4-9-7.4-17.7v-37.9h-13.8v-11.7H616V99.2l12.2-3.6v19.6h18.6v11.7H646.9z"/>
                <path d="M723.8,176.2c-7.2,7.1-15.9,10.6-26.2,10.6s-19-3.5-26.1-10.6s-10.7-15.8-10.7-26.2s3.6-19.1,10.7-26.2   c7.1-7.1,15.8-10.6,26.1-10.6s19,3.5,26.2,10.6s10.8,15.8,10.8,26.2S731,169.2,723.8,176.2z M697.7,175c7,0,12.9-2.4,17.6-7.1   c4.8-4.8,7.1-10.7,7.1-17.8s-2.4-13-7.1-17.8c-4.8-4.8-10.6-7.1-17.6-7.1c-6.9,0-12.7,2.4-17.5,7.1c-4.8,4.8-7.1,10.7-7.1,17.8   s2.4,13,7.1,17.8C685,172.6,690.8,175,697.7,175z"/>
                <path d="M768.2,126.9c4-8.7,11.3-13,21.8-13v12.7c-6-0.3-11.1,1.3-15.4,4.8c-4.3,3.5-6.4,9-6.4,16.6v37.1H756v-69.9h12.2   L768.2,126.9L768.2,126.9z"/>
                <path d="M859.2,115.1h13l-28.5,74.3c-3,7.9-7.2,14-12.5,18.1c-5.4,4.1-11.5,6-18.5,5.5v-11.3c8.6,0.7,14.9-4.1,18.9-14.3l1.3-2.7   l-30.5-69.6h13l23.8,54.8L859.2,115.1z"/>
            </g>
            <g>
                <polygon points="207.7,0 55.8,0 0,0 0,55.9 0,127.6 55.8,127.6 55.8,55.9 207.7,55.9  "/>
                <polygon points="151.9,113.5 118.3,113.5 118.3,170.2 151.9,170.2 151.9,226.9 89.6,226.9 89.6,283.7 207.7,283.7 207.7,239    207.7,228.8 207.7,168.4 207.7,113.5  "/>
            </g>
        </svg>
    )
    return (
        <NavLink to="/" end className={c.logo}>
            {svg}
        </NavLink>
    )
}

export default Logo