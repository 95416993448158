import c from './styles.module.scss'
import Container from '../container'
import { ADVANTAGES } from '../../config'

const AdvantagesRow = () => {

    return (
        <section className={c.section}>
            <Container>
                <div className={c.inner}>
                    <div className={c.grid}>
                        {
                            ADVANTAGES.map((data, index) => {
                                const {icon, text} = data

                                return (
                                    <div className={c.item} key={index}>
                                        <div className={c.itemContent}>
                                            <div className={[c.icon, 'icon-' + icon].join(' ')}></div>
                                            <div className={c.text}>{text}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default AdvantagesRow