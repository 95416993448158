import React, {useRef, useEffect } from "react";
import Button from "../UI/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import useCosmic from "../../hooks/useCosmic";
import c from "./styles.module.scss";
import Container from "../container";
import PostItem from "../postItem";

const PostSlider = () => {
	const prevBtn = useRef(null);
	const nextBtn = useRef(null);

	const { posts, error, loading} = useCosmic(7);

	useEffect(() => {
		if (prevBtn.current && nextBtn.current) {
			// Необходимо, чтобы элементы были доступны
		}
	}, [prevBtn, nextBtn]);

	return (
		<section className={c.slider}>
			<Container>
				<div className="h2">Свежие статьи</div>
				<div className={c.inner}>
					<div className={[c.sliderNav, "dark"].join(" ")}>
						<Button
							ref={prevBtn}
							icon="arrow-left"
							size="smaller"
						/>
						<Button
							ref={nextBtn}
							
							icon="arrow-right"
							size="smaller"
						/>
					</div>
					{loading && <p>Загрузка...</p>}
					{error && <p>{error}</p>}
					{!loading && !error && posts.length > 0 && (
						<Swiper
							autoplay={{ delay: 5000, disableOnInteraction: false }}
							modules={[Navigation, Autoplay]}
							spaceBetween={40}
							navigation={{
								prevEl: prevBtn.current,
								nextEl: nextBtn.current,
							}}
							onBeforeInit={(swiper) => {
								swiper.params.navigation.prevEl = prevBtn.current;
								swiper.params.navigation.nextEl = nextBtn.current;
							}}
						>
							{posts.map((item, index) => (
								<SwiperSlide key={index}>
									<PostItem post={item} />
								</SwiperSlide>
							))}
						</Swiper>
					)}
				</div>
			</Container>
		</section>
	);
};

export default PostSlider;
