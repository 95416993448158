import React, { useEffect, useState } from "react";
import c from "./styles.module.scss";
import PostMarkdown from "../postMarkdown";
import Container from "../container/index";

const PostPreview = ({ post }) => {
	const { slug, title, metadata } = post;
	const [image, setImage] = useState(
		metadata?.["post-mobile-image"]?.["imgix_url"]
	);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 768) {
				setImage(metadata?.["post-desktop-image"]?.["imgix_url"]);
			} else {
				setImage(metadata?.["post-mobile-image"]?.["imgix_url"]);
			}
		};

		handleResize(); 
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [metadata]);

	return (
		<div className={c.preview}>
			<Container>
				<div className={c.inner}>
					<div className={c.billboard}>
						<img alt={slug} src={image} className={c.image} />
					</div>

					<div className={c.info}>
						<h1>{title}</h1>

						{metadata?.["post-body"] ? (
							<PostMarkdown body={metadata["post-body"]} />
						) : (
							<p>Пост не найден</p>
						)}
					</div>
				</div>
			</Container>
		</div>
	);
};

export default PostPreview;
