import PageHeader from "../components/pageHeader";
import BlogFeed from "../components/blogFeed";

const BlogPage = () => {
	return (
		<>
			{/* <MetaHelmet option={optionName} /> */}
			<PageHeader
				title="Блог"
				breadcrumbs={[
					{
						link: "/products",
						text: "Каталог",
					},
					{
						link: "/blog",
						text: "Блог",
					},
				]}
			/>
			<BlogFeed />
		</>
	);
};

export default BlogPage;
