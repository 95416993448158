import DefaultPage from "../components/defaultPage";
import PageHeader from "../components/pageHeader";
import TextBlock from "../components/textBlock";
import { Helmet } from "react-helmet";

const OfferPage = () => {
  return (
    <>
      <Helmet>
        <title>Публичаня оферта</title>
      </Helmet>
      <PageHeader
        title="Публичная оферта"ф
        breadcrumbs={[
          {
            link: "/",
            text: "Публичная оферта",
          },
        ]}
      />
      <DefaultPage>
        <TextBlock>
          <>
            <p>
              Перед покупкой товара внимательно ознакомьтесь с текстом настоящей
              публичной оферты. В случае несогласия с каким-либо из пунктов
              оферты Вы вправе отказаться от покупки товаров или использования
              услуг, предоставляемых продавцом.
            </p>
            <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
            <ol>
              <li>
                «Товар» – любая вещь, принадлежащая Продавцу на праве
                собственности, которая представлена на Сайте и предлагаемая
                Продавцом для купли-продажи в соответствии с условиями Публичной
                оферты.
              </li>
              <li>
                «Продавец» – ТОО «Swiss Time Company», реализующий Товар
                Потребителям.
              </li>
              <li>
                «Оферта (Публичная оферта)» – размещённое на Сайте настоящее
                предложение Продавца, содержащее все существенные условия
                договора купли-продажи, из которого усматривается воля Продавца
                заключить сделку купли-продажи на указанных в Оферте условиях с
                любым Потребителем, кто отзовётся.
              </li>
              <li>
                «Потребитель» – любое физическое или юридическое лицо, которое
                приобрело или желающее приобрести Товар у Продавца для
                собственных нужд на условиях Публичной оферты.
              </li>
              <li>
                «Договор» – соглашение между Продавцом и Потребителем в виде
                договора купли-продажи, которое содержит все существенные и иные
                условия Публичной оферты. Договор является результатом
                совершения Потребителем Акцепта настоящей Публичной оферты.
              </li>
              <li>
                «Акцепт» – полное и безоговорочное принятие Потребителем всех
                условий Публичной оферты в порядке, установленном Офертой, что
                равносильно заключению договора купли-продажи в соответствии с
                законодательством РК.
              </li>
              <li>
                «Заказ» – адресованное Продавцу устное или письменное поручение
                Потребителя подготовить и/или доставить Товар для его передачи в
                собственность Потребителя.
              </li>
              <li>
                «Сайт» – совокупность электронных документов (файлов) Продавца,
                доступных в сети Интернет по адресу{" "}
                <a href="https://g-factory.kz">www.g-factory.kz</a>.
              </li>
              <li>«Магазин» – стационарные торговые объекты Продавца.</li>
            </ol>
            <h2>ПРЕДМЕТ ПУБЛИЧНОЙ ОФЕРТЫ (ДОГОВОРА)</h2>
            <ol>
              <li>
                В соответствии со статьей 395 Гражданского кодекса Республики
                Казахстан настоящий документ является Публичной офертой и при
                желании Потребителя заключить с Продавцом Договор, Потребитель
                должен совершить Акцепт настоящей Оферты путем оформления Заказа
                и оплаты Товара в порядке, установленном Публичной офертой. С
                этого момента Договор считается заключенным на условиях
                Публичной оферты и обязательным для его сторон.
              </li>
              <li>
                По Договору Продавец обязуется передать Товар в собственность
                Потребителю, а Потребитель обязуется принять Товар и оплатить за
                него определённую денежную сумму (цену) в соответствии с ценами,
                размещёнными на Сайте или на ценниках в Магазине.
              </li>
              <li>
                Все приложения к Договору являются официальными документами
                Продавца и неотъемлемой частью настоящей Оферты.
              </li>
            </ol>
            <h2>ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА</h2>
            <ol>
              <li>
                Все информационные материалы, представленные на Сайте, носят
                справочный характер и не могут в полной мере передавать
                достоверную информацию об определенных свойствах и
                характеристиках Товара, таких как: цена, цвет, форма, размер и
                упаковка. В случае возникновения у Потребителя вопросов,
                касающихся свойств и характеристик Товара, перед размещением
                Заказа ему необходимо обратиться за консультацией или направить
                запрос на адрес электронной почты Продавца.
              </li>
              <li>
                В случае возникновения у Потребителя необходимых для принятия
                решения о покупке Товара вопросов, касающихся свойств и
                характеристик Товара, перед оформлением Заказа, Потребитель
                вправе обратиться к оператору Продавца по размещенным на Сайте
                контактам или обратиться непосредственно в Магазин. В случае не
                обращения за дополнительной необходимой информацией
                Потребителем, Продавец не несет ответственности за выбранный
                Потребителем Товар.
              </li>
              <li>
                Если Продавцу необходима дополнительная информация, он вправе
                запросить её у Потребителя. В случае не предоставления
                необходимой информации Потребителем, Продавец не несёт
                ответственности за выбранный Потребителем Товар.
              </li>
              <li>
                Заказ может быть оформлен Потребителем самостоятельно через
                Сайт, через оператора Продавца или в Магазине, при этом:
                <ol>
                  <li>
                    При оформлении Заказа через оператора Продавца или в
                    Магазине, Потребитель устно предоставляет полную
                    запрашиваемую информацию, необходимую для оформления Заказа.
                    После оформления Заказа через оператора Продавца, данные о
                    Потребителе регистрируются в его базе данных. После
                    обработки Заказа, Продавец, проверив наличие Товара на
                    складе, обязательно, посредством телефонного звонка своего
                    оператора, производит утверждение Заказа.
                  </li>
                  <li>
                    При оформлении Заказа через Сайт, Потребитель обязуется
                    предоставить регистрационную информацию о себе, заполнить
                    электронную форму Заказа и отправить сформированный Заказ
                    Продавцу посредством сети Интернет.
                  </li>
                </ol>
              </li>
              <li>
                Оформление Потребителем Заказа через Сайт осуществляется путём
                выполнения следующих последовательных действий:
                <ul>
                  <li>
                    из каталога Товаров осуществить выбор Товара и добавить в
                    «Корзину» выбранный Товар.
                  </li>
                  <li>
                    проверить, соответствует ли ассортимент в «Корзине»
                    выбранным Товарам, и при необходимости добавить или удалить
                    Товары из «Корзины», после чего нажать на кнопку «Оформить
                    заказ».
                  </li>
                  <li>
                    заполнить электронную форму Заказа: фамилия, имя
                    Потребителя; адрес, по которому следует доставить Товар
                    (если доставка до адреса Потребителя); адрес электронной
                    почты; контактный телефон. Наименование, количество,
                    ассортимент, цена выбранного Потребителем Товара указываются
                    в корзине Потребителя на Сайте. Нажимая кнопку «Оформить
                    заказ», Потребитель подтверждает, что ознакомлен, согласен и
                    принимает условия Публичной оферты.
                  </li>
                </ul>
              </li>
              <li>
                Каждое оформление Потребителем Заказа рассматривается как
                самостоятельное заключение с ним Договора в отношении Товара,
                определенного таким Заказом. Не совершение Потребителем любого
                из указанных Продавцом действий или их совершение Потребителем с
                нарушением условий Договора, считается отказом Потребителя от
                Акцепта настоящей Оферты и заключения Договора.
              </li>
              <li>
                В случае изменения срока доставки, либо отсутствия заказанных
                Товаров на складе Продавца после завершения процедуры оформления
                Заказа, Продавец направляет Потребителю уведомление об
                изменениях условий Договора по телефону или путем направления
                электронного сообщения по адресу электронной почты или номеру
                телефона, указанному при формировании Потребителем Заказа с
                целью получения согласия на новые условия выполнения Заказа в
                целом, либо в части. При этом Потребитель соглашается с новыми
                условиями Заказа или аннулирует Заказ полностью или частично.
                При предварительной оплате Потребителем Заказа в случае полного
                или частичного отказа от Заказа Потребителю возвращается
                стоимость Товара, от которого Потребитель отказался, способом,
                которым был оплачен Товар. Потребитель выражает свое согласие с
                новыми условиями Заказа путем: устного подтверждения по телефону
                или письменного подтверждения путем направления электронного
                сообщения по электронной почте/телекоммуникационной связи либо
                путем принятия Товаров по измененным условиям Заказа.
              </li>
              <li>
                Доставка заказанного Товара Потребителю осуществляется по
                согласованию с ним по адресу, указанному самим Потребителем.
                Способы, стоимость, а также примерные сроки доставки Товаров,
                указываются в Разделе 4 настоящей Оферты, а также в разделе
                Сайта «Доставка и оплата».
              </li>
              <li>
                Потребитель при оформлении Заказа вправе указать имя другого
                лица в качестве получателя Товара, с указанием адреса доставки,
                в качестве поручения Потребителя Продавцу. В таком случае
                Продавец исполняет такое поручение и доставляет Товар другому
                лицу в качестве получателя Товара, за исключением случаев где
                доставка и передача Товара третьему лицу невозможна.
              </li>
              <li>
                Заказ считается исполненным в момент фактической передачи Товара
                Потребителю/третьему лицу, указанному Потребителем в Заказе.
                Расписываясь в товарной накладной предоставляемой курьером
                курьерской службы Продавца или сообщая верификационный СМС-код
                курьеру, Потребитель/третье лицо подтверждают исполнение Заказа.
              </li>
              <li>
                Право собственности на Товар и связанные с ним риски переходит
                от Продавца к Потребителю/третьему лицу в момент фактической
                передачи Товара и подписания необходимых сопроводительных
                документов на Товар.
              </li>
              <li>
                Продавец не несет ответственности, если ожидания Потребителя о
                потребительских свойствах Товара не оправдались.
              </li>
              <li>
                Продавец не несет ответственности за содержание и достоверность
                информации, предоставленной Потребителем при оформлении Заказа.
              </li>
              <li>
                Продавец обязуется принять Заказ в день его оформления, а если
                Заказ был сформирован Потребителем в конце или по истечении
                рабочего дня – на следующий рабочий день. Срок исполнения Заказа
                зависит от наличия заказанных позиций Товара на складе Продавца
                и времени, необходимого на обработку Заказа. Срок исполнения
                Заказа в исключительных случаях может быть оговорен с
                Потребителем индивидуально в зависимости от характеристик и
                количества заказанного Товара. В случае отсутствия одного или
                нескольких Товаров на складе Продавца, в том числе по причинам,
                не зависящим от последнего, Продавец вправе аннулировать
                указанный Товар из Заказа Потребителя. Продавец незамедлительно
                обязуется уведомить Потребителя об изменении его Заказа путем
                направления сообщения на электронный адрес, указанный при
                регистрации на Сайте, или устно по телефону, или дополнительным
                письменным пояснением на товарном/фискальном чеке при
                непосредственном получении Заказа Потребителем.
              </li>
            </ol>
            <h2>ПЕРЕДАЧА ТОВАРА ПОТРЕБИТЕЛЮ</h2>
            <ol>
              <li>
                Передача Товара в соответствии с условиями Заказа осуществляется
                путём самовывоза или на условиях доставки Товара.
              </li>
              <li>
                Продавец оказывает Потребителю услуги по доставке Товара одним
                из способов, указанных на Сайте по ссылке
                <a href="https://g-factory.kz/delivery">
                  https://www.g-factory.kz/delivery
                </a>
                .
              </li>
              <li>
                Передача Товара осуществляется Потребителю или третьему лицу,
                указанному в Заказе. В случае покупки Товара в кредит /
                рассрочку, передача Товара производится только Потребителю,
                оформившему кредит / рассрочку и осуществляется после
                прохождения Потребителем процедуры идентификации согласно
                правилам, утвержденным Продавцом.
              </li>
              <li>
                Доставка Товара осуществляется службой доставки Продавца или
                иным другим уполномоченным Продавцом лицом в пределах
                административно-территориальных границ населенных пунктов, в
                которых расположены Магазины.
              </li>
              <li>
                Доставка Товара осуществляется по указанному Потребителем в
                Заказе адресу (адрес доставки). Способы, а также примерные сроки
                доставки Товаров указываются на Сайте и/или при оформлении
                Заказа. Продавец вправе перед выдачей Товара идентифицировать
                Потребителя по правилам, утвержденным Продавцом, и отказать в
                выдаче, если процедура не пройдена.
              </li>
              <li>
                Для передачи Товара при доставке Потребитель или третье лицо
                обязаны предоставить:
                <ol>
                  <li>
                    сведения о Заказе (ФИО Потребителя, номер телефона,
                    указанный при оформлении и/или номер и дату Заказа);
                  </li>
                  <li>
                    документ, удостоверяющий личность с индивидуальным
                    идентификационным номером (за исключением случаев, когда
                    физическому лицу не присвоен индивидуальный
                    идентификационный номер в соответствии с законодательством
                    Республики Казахстан);
                  </li>
                  <li>
                    подтверждение произведенной оплаты – по требованию Продавца
                    и его уполномоченных лиц.
                  </li>
                </ol>
              </li>
              <li>
                Для передачи Товара при самовывозе Потребитель или третье лицо
                обязаны предоставить:
                <ol>
                  <li>
                    по требованию Продавца или его уполномоченных лиц документ,
                    удостоверяющий их личность;
                  </li>
                  <li>
                    номер и дату Заказа (данную информацию Потребитель может
                    получить в виде SMS на номер мобильного телефона и/или на
                    электронную почту, указанные при оформлении Заказа на Сайте
                    и/или в Магазине);
                  </li>
                  <li>
                    по требованию Продавца или его уполномоченных лиц
                    подтверждение произведенной оплаты;
                  </li>
                  <li>
                    пройти верификацию, согласно правилам, утвержденным
                    Продавцом.
                  </li>
                </ol>
              </li>
              <li>
                Если получателем Заказа выступает юридическое лицо, для
                получения Товара необходима доверенность, оформленная надлежащим
                образом и документ, подтверждающий личность поверенного.
              </li>
              <li>
                Информация о Товаре доводится до сведения Потребителя в
                технической документации, прилагаемой к Товару, на этикетках,
                путем нанесения маркировки или иным способом, принятым для
                отдельных видов Товаров.
              </li>
            </ol>
            <h2>ПРИЕМКА ТОВАРА ПОТРЕБИТЕЛЕМ</h2>
            <ol>
              <li>
                При приемке Товара Потребитель/третье лицо обязаны:
                <ol>
                  <li>
                    Провести осмотр на предмет наличия внешнего повреждения
                    упаковки.
                  </li>
                  <li>
                    Убедившись в сохранности упаковки и в отсутствии на ней
                    следов вскрытия и внешних повреждений, открыть
                    потребительскую упаковку и проверить наличие в ней Товара.
                  </li>
                  <li>
                    Убедившись в наличии Товара, проверить отсутствие на нем
                    следов внешних механических повреждений и сверить артикул
                    получаемого Товара с артикулом, проставленным в
                    сопроводительном документе, проверить комплектацию и цвет
                    Товара.
                  </li>
                  <li>
                    В случае отсутствия внешних повреждений упаковки и Товара, а
                    также в случае соответствия артикула, комплектации и цвета
                    Товара, подписать сопроводительные документы, подтверждающие
                    получение Товара, его соответствие условиям Заказа и
                    отсутствие претензий к Продавцу.
                  </li>
                  <li>
                    В случае наличия претензий потребовать от работника службы
                    доставки или уполномоченного лица Продавца указать в листе
                    доставки или ином сопроводительном документе все повреждения
                    упаковки Товара, механические повреждения Товара и/или его
                    некомплектность, несоответствие иным условиям Заказа, либо
                    отказаться от приема Товара.
                  </li>
                </ol>
              </li>
            </ol>
            <h2>ВОЗВРАТ И ОБМЕН ПРИОБРЕТЕННОГО ТОВАРА</h2>
            <ol>
              <li>
                Порядок возврата и обмена приобретенного Товара размещены на
                Сайте.
              </li>
              <li>
                При возникновении претензий относительно качества Товара,
                Потребитель вправе предъявить их в течение гарантийного срока на
                Товар в соответствии с законодательством РК.
              </li>
              <li>
                Потребитель вправе в течение четырнадцати дней с момента
                передачи ему Товара, обменять купленный Товар в месте покупки
                или иных местах, объявленных Продавцом, на аналогичный Товар
                другого размера, формы, габарита, фасона, расцветки,
                комплектации и тому подобное либо на другой Товар по соглашению
                сторон, произведя в случае разницы в цене необходимый перерасчет
                с Продавцом. При отсутствии необходимого для обмена Товара у
                Продавца Потребитель вправе возвратить приобретенный Товар
                Продавцу и получить уплаченную за него денежную сумму, в этом
                случае Потребитель возмещает Продавцу накладные расходы за
                доставку Товара.
              </li>
              <li>
                Перед возвратом/обменом Товара обязательно проводится экспертиза
                в авторизированных сервисных центрах бренда завода-изготовителя.
              </li>
              <li>
                Замена или возврат Товара с выявленным в процессе эксплуатации
                скрытым производственными дефектом (существенным недостатком)
                производится на основаниях и в сроки, установленные
                законодательством РК.
              </li>
              <li>
                В случае возникновения проблем с Товаром необходимо убедиться,
                что путей решения проблемы с Товаром собственными силами нет в
                инструкции по эксплуатации. Если самостоятельно устранить
                проблему не получилось, необходимо обратиться в авторизованный
                сервисный центр для диагностики Товара. В случае подтверждения
                заводского брака Потребитель может потребовать устранить
                неисправности, либо обратиться в тот Магазин, где был приобретен
                Товар для предъявления требований о замене или возврате Товара.
              </li>
              <li>
                Продавец обязан обеспечить обмен или возврат Товара надлежащего
                качества, если он не был в употреблении, сохранены его товарный
                вид, потребительские свойства, пломбы, ярлыки, а также документ,
                подтверждающий факт приобретения Товара, в течение четырнадцати
                календарных дней, если более длительный срок не установлен
                Договором, со дня приобретения Товара.
              </li>
              <li>
                При обмене или возврате Потребителем Товара надлежащего качества
                Продавец вправе требовать от Потребителя возмещения расходов,
                понесенных Продавцом при организации обмена или возврата Товара.
              </li>
              <li>
                В случае доставки Продавцом Товара ненадлежащего качества,
                Потребитель обязуется предоставить Товар Продавцу в порядке и на
                условиях предусмотренным Продавцом, в максимально короткие сроки
                с момента покупки для осуществления проверки качества Товара.
              </li>
              <li>
                При наличии законных оснований для обмена Товара или возврата
                денежных средств Потребителю, возврат/обмен Товара
                осуществляется по согласованию с Продавцом в Магазине в
                соответствии с условиями Договора.
              </li>
              <li>
                Продавец имеет право отказать в приемке возвращаемого Товара
                надлежащего качества, при наличии одного из следующих оснований:
                если Товар был в употреблении, не сохранены его товарный вид,
                потребительские свойства, пломбы, ярлыки, отсутствует документ,
                подтверждающий факт приобретения Товара, при отсутствии упаковки
                или при потере её товарного вида, обнаружении внешних
                повреждений Товара, несоответствии маркировки на Товаре,
                упаковке, документации, прилагаемой к Товару или размещенной на
                сайте, а также в иных случаях предусмотренных законодательством
                Республики Казахстан.
              </li>
              <li>
                При обмене или возврате Потребителем Товара надлежащего качества
                Продавец вправе требовать от Потребителя возмещения расходов,
                понесенных Продавцом при организации обмена или возврата Товара.
              </li>
            </ol>
            <h2>ЦЕНА И ПОРЯДОК ОПЛАТЫ ТОВАРА</h2>
            <ol>
              <li>
                Цена Товара указывается на Сайте и/или на ценниках в Магазине, в
                тенге. В случае неверного указания Продавцом цены заказанного
                Потребителем Товара, Продавец имеет право изменить цену Товара и
                при первой доступной возможности информирует об этом Потребителя
                для подтверждения Заказа с измененными условиями либо
                аннулирования Заказа. При невозможности связаться с Потребителем
                или в случае отказа Потребителя от Заказа с измененными
                условиями в течение 24 часов с момента размещения заказа, данный
                Заказ считается аннулированным.
              </li>
              <li>
                В случае неполной оплаты стоимости заказа Договор считается
                расторгнутым, в этом случае Продавец обязан возвратить деньги
                Потребителю (если они были получены).
              </li>
              <li>
                Оплата Товара производится способами, указанными на Сайте.
              </li>
              <li>
                Перед отпуском Заказа Продавец проверяет стоимость Товара на
                соответствие цены, наименования и т.д.
              </li>
              <li>
                В случае неполной оплаты стоимости заказа Договор считается
                расторгнутым, в этом случае Продавец обязан возвратить деньги
                Потребителю (если они были получены).
              </li>
              <li>
                Заказ аннулируется, если Потребитель не выходит на связь с
                Продавцом в течение 24 часов с момента размещения Заказа.
              </li>
              <li>
                Продавец вправе применять и устанавливать скидки и иные
                специальные предложения, устанавливаемые правилами или иными
                документами Продавца, информация о которых размещается на Сайте
                и/или в Магазине.
              </li>
              <li>
                Оплата бонусами, подарочными картами/ сертификатами, применения
                промокода или иных скидок производится при оформлении Заказа.
              </li>
            </ol>
            <h2>ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
            <ol>
              <li>
                Продавец обязан:
                <ol>
                  <li>
                    Передать Потребителю Товар в соответствии с условиями
                    Заказа.
                  </li>
                  <li>
                    Гарантировать соответствие качества Товара требованиям для
                    аналогичных Товаров, действующим на территории РК.
                  </li>
                  <li>
                    Рассматривать претензии и заявления Потребителей и
                    своевременно отвечать на них, в соответствии с действующим
                    законодательством РК и в случае обоснованности претензии
                    принимать меры к их удовлетворению.
                  </li>
                  <li>
                    Осуществлять сбор и обрабатывать персональные данные, в том
                    числе биометрические данные, при необходимости осуществлять
                    трансграничную передачу персональных данных Потребителей,
                    предоставленные им, обеспечивать их конфиденциальность и не
                    предоставлять доступ к этой информации третьим лицам, за
                    исключением случаев, предусмотренных действующим
                    законодательством РК и условиями Договора.
                  </li>
                  <li>
                    Исполнять иные обязанности, установленные Договором и/или
                    законодательством РК.
                  </li>
                </ol>
              </li>
              <li>
                Продавец имеет право:
                <ol>
                  <li>
                    Изменять Оферту, информацию о свойствах и характеристиках
                    Товара, цену Товаров, условий акций и тарифы на
                    сопутствующие услуги, способы и сроки оплаты и доставки
                    Товара в одностороннем порядке, с момента размещения на
                    Сайте.
                  </li>
                  <li>
                    На передачу персональных данных Потребителя службе доставки
                    Продавца или иным уполномоченным Продавцом лицам с целью
                    доставки Потребителю заказанных последним Товаров и оказания
                    услуг или исполнения иных обязательств по Договору.
                  </li>
                  <li>
                    Отказаться от исполнения своих обязательств по Договору в
                    случае возникновения обстоятельств непреодолимой силы,
                    включая, но не ограничиваясь: введением ограничительных
                    (карантинных) мер на основании нормативно-правовых актов
                    уполномоченных государственных органов.
                  </li>
                  <li>
                    Отказать в приемке возвращаемого Товара надлежащего
                    качества, при наличии одного из следующих оснований: если
                    Товар был в употреблении, не сохранены его товарный вид,
                    потребительские свойства, пломбы, ярлыки, отсутствует
                    документ, подтверждающий факт приобретения Товара, при
                    отсутствии упаковки или при потере ее товарного вида,
                    обнаружении внешних повреждений Товара, несоответствии
                    маркировки на Товаре, упаковке, документации, прилагаемой к
                    Товару или размещенной на Сайте, а также в иных случаях
                    предусмотренных законодательством Республики Казахстан.
                  </li>
                  <li>
                    При обмене или возврате Потребителем Товара надлежащего
                    качества потребовать от Потребителя возмещения расходов,
                    понесенных Продавцом при организации обмена или возврата
                    Товара.
                  </li>
                  <li>
                    Без согласования с Потребителем передавать свои права и
                    обязанности по исполнению Договора третьим лицам, а также
                    привлекать третьих лиц для исполнения своих обязательств по
                    Договору.
                  </li>
                  <li>
                    Отказать в частичном возврате Товара надлежащего качества,
                    приобретенного в рамках одного Заказа в кредит или
                    рассрочку, или на условиях маркетинговых Акций.
                  </li>
                  <li>
                    На осуществление записи телефонных переговоров с
                    Потребителем, на их хранение, использование, передачу
                    третьим лицам, отправку СМС-сообщений Потребителю.
                    Телефонные разговоры записываются в целях, включая, но не
                    ограничиваясь: для осуществления контроля качества
                    исполнения Заказов, а также в целях контроля качества
                    обслуживания клиентов работниками Продавца или
                    уполномоченных Продавцом лиц.
                  </li>
                  <li>
                    Осуществлять иные права, установленные Договором и/или
                    законодательством РК.
                  </li>
                </ol>
              </li>
              <li>
                Потребитель обязан:
                <ol>
                  <li>
                    Указывать при оформлении Заказа точное наименование
                    требуемого Товара, его номер по каталогу или все данные
                    необходимые для точного определения требуемого Товара (цвет,
                    вариант исполнения).
                  </li>
                  <li>
                    Оплачивать Товар по ценам, указанным на Сайте или на
                    ценниках в Магазине.
                  </li>
                  <li>
                    При оформлении Заказа предоставлять о себе достоверную и
                    достаточную информацию, путем заполнения электронной формы
                    Заказа на Сайте или предоставления информации оператору
                    Продавца по телефону или продавцу в Магазине. Продавец не
                    несет ответственности за ненадлежащее исполнение Заказа в
                    случае, если Потребитель предоставил неточную информацию о
                    его контактных данных, либо о Товарах, месте доставки или
                    получателе.
                  </li>
                  <li>
                    Принять Товар от Продавца в срок действия Заказа, указанный
                    на Сайте. В случае неявки Потребителя или не совершения иных
                    необходимых действий для принятия Товара в указанный срок,
                    может рассматриваться Продавцом в качестве отказа
                    Потребителя от исполнения Договора и отмены Заказа. При этом
                    Продавец вправе начислить, а Потребитель обязуется
                    возместить стоимость дополнительных расходов Продавца по
                    обеспечению передачи Товара Потребителю, а также расходы по
                    хранению Товара сверх указанного срока по ставкам,
                    определенным Продавцом.
                  </li>
                  <li>
                    При возврате или обмене Товара предоставить Продавцу по его
                    требованию заключения авторизованных сервисных центров
                    заводов-изготовителей.
                  </li>
                  <li>
                    Исполнять иные обязанности, установленные Договором и/или
                    законодательством РК.
                  </li>
                </ol>
              </li>
              <li>
                Потребитель имеет право:
                <ol>
                  <li>
                    Требовать от Продавца предоставления оплаченного Товара.
                  </li>
                  <li>
                    Потребовать возврата предоплаты в случае невозможности
                    исполнения Продавцом условий Заказа.
                  </li>
                  <li>
                    Возвращать и/или обменивать Товар в соответствии с условиями
                    Договора.
                  </li>
                  <li>
                    Внести изменения в Заказ до начала его исполнения Продавцом,
                    если Продавец согласен на такие изменения.
                  </li>
                  <li>
                    Обратиться за защитой своих нарушенных прав и законных
                    интересов: – к субъектам досудебного урегулирования
                    потребительских споров, утвержденных уполномоченным органом
                    РК; – в судебные органы, в соответствии с законодательством
                    Республики Казахстан.
                  </li>
                  <li>
                    Осуществлять иные права, установленные Договором и/или
                    законодательством РК.
                  </li>
                </ol>
              </li>
            </ol>
            <h2>УСЛОВИЯ ГАРАНТИИ</h2>
            <ol>
              <li>
                Гарантийный срок на приобретаемый Потребителем Товар,
                гарантийные обязательства Продавца, а также случаи, при которых
                гарантийное обслуживание не оказывается Продавцом и/или
                гарантийные обязательства прекращаются, определяются правилами,
                размещёнными на Сайте по ссылке
                <a href="https://www.g-factory.kz/service">
                  https://www.g-factory.kz/service
                </a>
                .
              </li>
              <li>
                При возникновении претензий относительно качества Товара,
                Потребитель вправе предъявить их в течение гарантийного срока на
                Товар в соответствии с законодательством РК.
              </li>
              <li>
                Гарантийный срок, в течение которого Потребитель может
                предъявить Продавцу претензии по качеству Товара, относящиеся к
                производственным дефектам, вследствие чего приобретённый Товар
                невозможно использовать по назначению, определяется также в
                гарантийном талоне на Товар. Утрата оригинала гарантийного
                талона на Товар является основанием для отказа Продавцом в
                гарантийном обслуживании приобретенного Потребителем Товара.
              </li>
              <li>
                Претензии относительно потери работоспособности Товара в ходе
                его эксплуатации в случаях, на которые распространяются
                гарантийные обязательства производителя Товара, рассматриваются
                на основании заключений авторизованных сервисных центров. Адреса
                и телефоны авторизованных сервисных центров указаны в
                документации, прилагаемой к Товару или размещены на Сайте.
              </li>
              <li>
                Гарантийный ремонт осуществляется в сервисных центрах Продавца,
                авторизованных производителем сервисных центрах, а также в
                сервисных центрах партнеров Продавца.
              </li>
              <li>
                Взаимоотношения, не урегулированные размещенными на Сайте
                условиями гарантии, определяются законодательством Республики
                Казахстан.
              </li>
              <li>
                Товар подлежит гарантийному обслуживанию в случаях, если
                уведомление о неисправности Товара получено Продавцом до
                истечения гарантийного срока; Товар эксплуатировался без
                нарушений правил эксплуатации, указанных в сопроводительной
                заводской документации; Товар имеет оригинальные неизменные
                надписи серийного номера и даты изготовления, а также заводскую
                упаковку; Потребитель не проводил ремонт Товара собственными
                силами либо силами третьих лиц.
              </li>
              <li>
                При наличии претензий по качеству Товара будет произведен его
                ремонт, в случае признания Товара неремонтопригодным – замена
                Товара, при невозможности замены – возврат суммы, уплаченной за
                такой Товар.
              </li>
              <li>
                Расходы по проведению дополнительной проверки (экспертизы)
                оплачиваются Потребителем. В случае если недостатки Товара
                возникли по вине Продавца/завода-изготовителя, расходы по
                проведению дополнительной проверки (экспертизы) возмещаются
                Продавцом Потребителю. Если в результате проверки (экспертизы)
                Товара установлено, что недостатки Товара отсутствуют или
                возникли после передачи Товара Потребителю вследствие нарушения
                им установленных правил использования, хранения или действий
                третьих лиц либо непреодолимой силы, Потребитель обязан
                возместить Продавцу (заводу-изготовителю) расходы на проведение
                проверки (экспертизы), а также связанные с её проведением
                расходы на транспортировку Товара.
              </li>
              <li>
                В случае выявления недостатков Товара, возникших из-за дефектов
                изготовления или материалов (то есть, вследствие обстоятельств,
                за которые отвечает завод-изготовитель) и при невозможности
                устранения этих недостатков, Потребитель имеет право обменять
                Товар или возвратить сумму, уплаченную за Товар при
                предоставлении заключения авторизированного сервисного центра с
                указанием, что причина поломки Товара является дефект
                завода-изготовления или материалов, а также документа,
                подтверждающего оплату Товара.
              </li>
            </ol>
            <h2>ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
            <ol>
              <li>
                Продавец не несет ответственности за:
                <ol>
                  <li>
                    ущерб, причиненный Потребителю вследствие ненадлежащего
                    использования Товара;
                  </li>
                  <li>
                    за приемку Потребителем Товара без проверки. В этом случае
                    Потребитель принимает все риски в случае, если Товар и/или
                    упаковка будут иметь внешние повреждения.
                  </li>
                </ol>
              </li>
              <li>
                Потребитель несёт ответственность за достоверность и
                достаточность предоставленной при оформлении Заказа информации.
                Стороны настоящим соглашаются, что в случае предоставления
                Потребителем некорректной или недостоверной информации Продавец
                не может гарантировать своевременную и надлежащую доставку
                Товара и исполнение Заказа, включая право Продавца аннулировать
                Заказ в одностороннем порядке с уведомлением Потребителя об
                этом.
              </li>
              <li>
                Стороны несут ответственность за неисполнение или ненадлежащее
                исполнение обязательств по Договору в соответствии с
                законодательством РК.
              </li>
              <li>
                Стороны освобождаются от ответственности за полное или частичное
                неисполнение своих обязательств, если неисполнение является
                следствием таких непреодолимых обстоятельств (форс-мажор) как:
                война или военные действия, землетрясение, наводнение, пожар и
                другие стихийные бедствия, акты или действия органов
                государственной власти, изменение таможенных правил, ограничения
                импорта и экспорта, введения ограничительных (карантинных) мер
                или чрезвычайного положения, возникших независимо от воли Сторон
                после заключения настоящего Договора. Сторона, которая не может
                исполнить свои обязательства, незамедлительно извещает об этом
                другую Сторону и предоставляет документы, подтверждающие наличие
                таких обстоятельств, выданные уполномоченными на то органами,
                при этом введение ограничительных (карантинных) мер или
                чрезвычайного положения не требуют дополнительного подтверждения
                от уполномоченных органов и подтверждения актами уполномоченных
                органов о введении таких мер.
              </li>
            </ol>
            <h2>ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
            <ol>
              <li>
                В случае возникновения вопросов и претензий со стороны
                Потребителя, он может обратиться к Продавцу по телефонам и
                электронной почте, размещенным на Сайте.
              </li>
              <li>
                Стороны примут все меры к разрешению споров и разногласий,
                которые могут возникнуть при исполнении обязательств по Договору
                путем переговоров.
              </li>
              <li>
                Досудебный порядок урегулирования споров и разногласий,
                вытекающих из Договора, является обязательным.
              </li>
              <li>
                В случае если Стороны не пришли к соглашению, все споры и
                разногласия подлежат рассмотрению в судебных органах РК по месту
                нахождения Продавца в соответствии с действующим
                законодательством РК.
              </li>
            </ol>
            <h2>КОНФИДЕНЦИАЛЬНОСТЬ И ЗАЩИТА ИНФОРМАЦИИ</h2>
            <ol>
              <li>
                Продавец обязуется обеспечить конфиденциальность и обработку
                персональных данных Потребителя, как субъекта персональных
                данных, с соблюдением всех применимых требований
                законодательства Республики Казахстан в области защиты
                персональных данных, в том числе с соблюдением принципов,
                требований, обязательств оператора персональных данных,
                установленных Законом Республики Казахстан от 21 мая 2013 года №
                94-V «О персональных данных и их защите».
              </li>
              <li>
                Под обработкой персональных данных в Договоре понимается любое
                действие или совокупность действий с персональными данными,
                совершаемых с использованием средств автоматизации или без
                использования таких средств, направленные на сбор, запись,
                систематизацию, накопление, хранение, изменение, дополнение,
                уточнение (обновление, изменение), извлечение, использование,
                распространение, обезличивание, блокирование, удаление,
                уничтожение, а также на трансграничную передачу персональных
                данных.
              </li>
              <li>
                Заключая Договор с Продавцом, Потребитель подтверждает свое
                согласие и разрешает Продавцу и его службам:
                <ul>
                  <li>
                    осуществлять сбор, хранение, обработку и использование своих
                    персональных данных, включая, но не ограничиваясь: фамилия,
                    имя, отчество, адрес доставки, адрес проживания, контактный
                    номер телефона, адрес электронной почты, дату рождения, пол,
                    ИИН, биометрические данные. Предоставленная информация
                    используется исключительно при обработке и исполнения Заказа
                    или для предоставления Потребителю доступа к специальной
                    информации, включая, но не ограничиваясь информации о
                    маркетинговых акциях. Личные сведения Потребитель может
                    изменить, обновить или удалить;
                  </li>
                  <li>
                    обрабатывать его персональные данные с помощью
                    автоматизированных систем управления базами данных, а также
                    иных программных и технических средств. Однако Продавец
                    вправе самостоятельно определять используемые способы
                    обработки персональных данных Потребителя (включая, но, не
                    ограничиваясь: автоматическая сверка почтовых кодов с базой
                    кодов/индексов, автоматическая проверка написания названий
                    улиц/населенных пунктов, сегментация базы данных по заданным
                    критериям и т.д.).
                  </li>
                </ul>
              </li>
              <li>
                Продавец обеспечивает конфиденциальность предоставленных
                Потребителем персональных данных, их защиту от копирования,
                распространения. В любой момент Потребитель вправе запросить
                перечень своих персональных данных и/или потребовать изменить,
                уничтожить свои персональные данные, обратившись непосредственно
                к Продавцу.
              </li>
              <li>
                Для обеспечения Потребителя информацией определённого рода,
                Продавец может присылать на указанный при регистрации адрес
                электронный почты и/или на телефон информационные сообщения, в
                том числе рекламные сообщения, включая, но не ограничиваясь в
                целях выполнения Продавцом обязательств перед Потребителем в
                рамках Договора, продвижения электронных и СМС-опросов, контроля
                результатов маркетинговых акций, клиентской поддержки,
                организации доставки Товара Потребителям, проведение розыгрышей
                призов среди Потребителей, контроля удовлетворенности
                Потребителя качеством услуг, оказываемых Продавцом. В любой
                момент Потребитель может изменить тематику такой рассылки или
                отказаться от неё.
              </li>
              <li>
                При оплате Заказа с помощью банковских карт информация о
                карточке и о ее держателе передаются в защищённом виде на
                авторизационный сервер банка и не предоставляется Продавцу или
                третьим лицам. При этом никакая конфиденциальная информация,
                кроме уведомления об оплате Продавцу не передаётся.
              </li>
              <li>
                Во исполнение требований Закона Республики Казахстан от 21 мая
                2013 года № 94-V «О персональных данных и их защите», Закона
                Республики Казахстан от 6 июля 2004 года № 573-II «О кредитных
                бюро и формировании кредитных историй в Республике Казахстан»,
                настоящим Потребитель дает свое согласие Продавцу, а также
                банкам второго уровня и микрофинансовым организациям,
                сотрудничающим с Продавцом, на обработку персональных данных
                Потребителя, в том числе зафиксированных на электронных,
                бумажных, и (или) иных носителях (далее – Персональные данные),
                предоставляемых через Сайт, посредством мобильного приложения (и
                не только) или в личном присутствии либо полученных от любых
                третьих лиц, а также Потребитель дает согласие на предоставление
                информации в кредитном бюро и информации из кредитных бюро, в
                том числе на выдачу кредитного отчета из кредитных бюро (включая
                данные, которые поступят в будущем), и подтверждает, что, давая
                такое согласие Потребитель действует по своей воле и в своем
                интересе.
              </li>
            </ol>
            <h2>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
            <ol>
              <li>
                Совершая Акцепт, Потребитель полностью и безоговорочно
                соглашается со всеми условиями настоящей Публичной оферты.
              </li>
              <li>
                Продавец имеет право отказать в размещении Заказа лицам,
                выражающим несогласие с условиями Публичной оферты.
              </li>
              <li>
                Продавец оставляет за собой право в одностороннем порядке
                вносить изменения и дополнения в настоящую Публичную оферту, в
                связи с чем Потребитель обязуется регулярно отслеживать
                изменения, публикуемые на Сайте.
              </li>
            </ol>
          </>
        </TextBlock>
      </DefaultPage>
    </>
  );
};

export default OfferPage;
