import { createStore, combineReducers } from 'redux'
import { authReducer } from './authReducer.js'
import { searchReducer } from './searchReducer.js'
import { cartReducer } from './cartReducer.js'
import { menuReducer } from './menuReducer.js'
import { promocodeReducer } from './promocodeReducer.js'

const rootReducer = combineReducers({
    auth: authReducer,
    search: searchReducer,
    cart: cartReducer,
    menu: menuReducer,
    promocode: promocodeReducer,
})
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const dispatchStore = store.dispatch
const getStoreState = store.getState

export { dispatchStore, getStoreState }
export default store