

export const popularModels = [
    238648,
     238668,
      238688,
       238708,
        238729,
         427324,
          974703,
           974705,
           238854,
           427329,
           1785723,
           238894,
           238973,
           239029

]