import $api from "../http";

export default class CartService {
	static async send(data) {
		return $api.post("/api/cart/", data);
	}
	static async verifyPromocode(promocode) {
		return $api.post("/api/cart/promocode", {
			text: promocode,
		});
	}
	static async userOrders(id) {
		return $api.get("/api/cart/user/", {
			params: { id },
		});
	}
}
