import { Helmet } from "react-helmet-async";

const MetaHelmet = ({ option }) => {
  let metaData = {
    title: "",
    description: "",
    type: "",
    name: ""
  };

  switch (option) {
    case "main":
      metaData = {
        title: "Купить Часы CASIO в Казахстане, G-Shock в Алматы и Астане",
        description: "Широкий выбор часов CASIO и G-Shock в Казахстане. Доступные цены, высокое качество и быстрая доставка.",
        type: "website"
      };
      break;
    case "catalog":
      metaData = {
        title: "Купить Часы CASIO в Казахстане в Алматы и Астане - Каталог",
        description: "Разнообразие часов CASIO  в нашем каталоге. Выбирайте и заказывайте прямо сейчас!",
        type: "website"
      };
      break; 
    case "menWatches":
      metaData = {
        title: "Купить Мужские Часы CASIO в Казахстане, Мужские Casio в Алматы и Астане",
        description: "Изысканные мужские часы CASIO для стильного мужчины. Покупайте сейчас!",
        type: "website"
      };
      break;
    case "womenWatches":
      metaData = {
        title: "Купить Женские Часы CASIO в Казахстане, Женские Casio в Алматы и Астане",
        description: "Очаровательные женские часы CASIO для элегантной леди. Выбирайте свой стиль!",
        type: "website"
      };
      break;
    case "sheen":
      metaData = {
        title: "Купить Часы Sheen в Казахстане в Алматы и Астане",
        description: "Изысканные часы Sheen для модных женщин. Подчеркните свою уникальность!",
        type: "website"
      };
      break;
    case "g-shock":
      metaData = {
        title: "Купить Часы G-Shock в Казахстане, G-Shock в Алматы и Астане",
        description: "Надежные часы G-Shock и другие модели для активного образа жизни. Сделайте свой выбор прямо сейчас!",
        type: "website"
      };
      break;
    case "general":
      metaData = {
        title: "Купить Часы CASIO в Казахстане, Casio в Алматы и Астане",
        description: "Широкий выбор часов CASIO и G-Shock в Казахстане. Доступные цены, высокое качество и быстрая доставка.",
        type: "website"
      };
      break;
    case "edifice":
      metaData = {
        title: "Купить Часы Edifice в Казахстане в Алматы и Астане",
        description: "Стильные и функциональные часы Edifice для успешных мужчин. Выбирайте качество и стиль!",
        type: "website"
      };
      break;
    case "pro-trek":
      metaData = {
        title: "Купить Часы Pro Trek в Казахстане в Алматы и Астане",
        description: "Надежные часы Pro Trek для любителей активного отдыха. Готовьтесь к новым приключениям!",
        type: "website"
      };
      break;
    case "baby-g":
      metaData = {
        title: "Купить Часы Baby-G в Казахстане в Алматы и Астане",
        description: "Яркие и стильные часы Baby-G для энергичных и стильных личностей. Выбирайте свой образ!",
        type: "website"
      };
      break;
    default:
      metaData = {
        title: "Купить Часы CASIO в Казахстане в Алматы и Астане",
        description: "Широкий выбор часов CASIO и G-Shock в Казахстане. Доступные цены, высокое качество и быстрая доставка.",
        type: "website"
      };
  }

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      {/* Facebook tags */}
      <meta property="og:type" content={metaData.type} />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:description" content={metaData.description} />
      {/* Twitter tags */}
      <meta name="twitter:creator" content={metaData.name} />
      <meta name="twitter:card" content={metaData.type} />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
    </Helmet>
  );
};

export default MetaHelmet;
