import { useState } from 'react'
import c from './styles.module.scss'
import TextBlock from '../textBlock'
import Hoverable from '../hoverable'

const AccordsItem = ({title, content, show, onClick}) => {
    return (
        <div className={[c.item, show ? c.show : null].join(' ')}>
            <Hoverable>
                <div className={c.title} onClick={onClick}>
                    <div className={c.text}>{title}</div>
                    <div className={c.indicator}></div>
                </div>
            </Hoverable>
            <div className={c.content}>
                <TextBlock>{content}</TextBlock>
            </div>
        </div>
    )   
}

const Accords = ({data, active = null}) => {
    const [opened, setOpened] = useState(active);

    return (
        <div className={c.accords}>
            {data.map((item, i) => {
                return (
                    <AccordsItem
                        key={i}
                        {...item}
                        show={i === opened}
                        onClick={() => {
                            setOpened( opened === i ? null : i )
                        }}
                    />
                )
            })}
        </div>
    )
}

export default Accords