import React from "react";
import c from "./styles.module.scss";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";


const PostMarkdown = ({ body }) => {
	return (
		<div className={c.markdownBody}>
			<Markdown remarkPlugins={[remarkGfm]}>{body}</Markdown>
		</div>
	);
};

export default PostMarkdown;
