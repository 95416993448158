import c from "./styles.module.scss";
import Container from "../container";
import Button from "../UI/button";
import Tag from "../UI/tag";
import image from "../../assets/images/about.webp";

const About = () => {
  return (
    <div className={c.about}>
      <Container>
        <div className={c.inner}>
          <div className={[c.content, "margin__smaller"].join(" ")}>
            <div className={c.title}>
              <Tag text="О нас" size="smaller" tagStyle="outline" />
              <h1 className="h2">
                Интернет магазин <br />
                G-Factory
              </h1>
            </div>
            <p>
              Добро пожаловать в официальный интернет-магазин G-Factory – вашего
              надежного партнера в мире времени и стиля!
            </p>
            <p>
              Casio – это не просто часы, это символ надежности и инноваций.
              Более полувековой опыт производства, технологические достижения и
              внимание к деталям делают Casio лидером в индустрии часового
              искусства. Выбирая Casio, вы выбираете качество и стиль.
            </p>
            <div className={c.btns}>
              <Button link="/products" text="В каталог" size="smaller" />
              <Button
                link="/contact"
                text="Контакты"
                btnStyle="grey"
                size="smaller"
              />
            </div>
          </div>
        </div>
      </Container>
      <div className={c.image}>
        <img src={image} alt="Интернет магазин G-Factory" loading="lazy" />
      </div>
    </div>
  );
};

export default About;
