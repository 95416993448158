import c from "./styles.module.scss";
import Checkbox from "../UI/checkbox";
import Hoverable from "../hoverable";
import Tag from "../UI/tag";
import PriceSlider from "../UI/priceSlider";
import { useState, useEffect } from "react";
import Button from "../UI/button";
import Spinner from "../spinner";
import { filtersString, toFiltersString } from "../../utils/filters";
import FiltersController from "../../controllers/filtersController";
import { useNavigate } from "react-router-dom";
import { priceFormate } from "../../utils/price";
import { getColors } from "../../utils/colors";
import CloseBtn from "../UI/closeBtn";
import Container from "../container";
import { useSelector } from "react-redux";

const checkboxColors = getColors();

const ArchiveFiltersItem = ({ title, applied = 0, open = false, children }) => {
  const [active, setActive] = useState(open);

  return (
    <div className={[c.item, active ? c.show : null].join(" ")}>
      <Hoverable>
        <div className={c.header} onClick={() => setActive(!active)}>
          <div className={c.title}>
            {title}
            {applied ? <div className={c.applied}>{applied}</div> : null}
          </div>
          <div className={[c.arrow, "icon-down"].join(" ")}></div>
        </div>
      </Hoverable>
      <div className={c.body}>{children}</div>
    </div>
  );
};

const CheckboxGroup = ({ title, value = false, onChange, color }) => {
  return (
    <Checkbox onChange={onChange} value={value} label={title} color={color} />
  );
};

const ProcessFiltersGroup = ({ data, applied, onChange }) => {
  const options = {
    id: data.data.id,
    title: data.data.name,
    items: data.children,
    applied: 0,
    open: false,
    active: [],
  };

  for (let id in options.items) {
    let numId = Number(id);

    if (applied.includes(numId)) {
      options.applied++;
      options.open = true;
      options.active.push(numId);
    }
  }

  return (
    <ArchiveFiltersItem
      title={options.title}
      open={options.open}
      applied={options.applied}
    >
      <div className={c.checkboxes}>
        {Object.values(options.items).map((item) => {
          const { id, name } = item;
          const value = options.active.includes(id);

          return (
            <CheckboxGroup
              key={id}
              id={1}
              title={name}
              value={value}
              color={
                options.title === "Цвет" && checkboxColors[name.toLowerCase()]
              }
              onChange={(value) => {
                onChange(options.id, id, value);
              }}
            />
          );
        })}
      </div>
    </ArchiveFiltersItem>
  );
};

const ArchiveFilters = ({ filters, ids, min, max }) => {
  const clearedFilters = filtersString(filters);
  const [newFilters, setNewFilters] = useState(clearedFilters);
  const [applied, setApplied] = useState([]);
  const [loading, setLoading] = useState(true);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [price, setPrice] = useState([0, 0]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const menu = useSelector((store) => store.menu);

  const getToggledFilters = (groupId, filterId, value) => {
    const filters = newFilters;

    if (value) {
      if (filters[groupId]) {
        if (!filters[groupId].includes(filterId)) {
          filters[groupId].push(filterId);
        }
      } else {
        filters[groupId] = [filterId];
      }
    } else {
      if (filters[groupId]) {
        const index = filters[groupId].indexOf(filterId);

        if (index !== -1) {
          filters[groupId].splice(index, 1);
        }

        if (!filters[groupId].length) {
          delete filters[groupId];
        }
      }
    }

    return filters;
  };

  const toggleFilters = (groupId, filterId, value) => {
    setNewFilters(getToggledFilters(groupId, filterId, value));
  };

  const fetchData = async () => {
    setLoading(true);
    // const getData = await FiltersController.getAll(ids, clearedFilters)
    const getData = await FiltersController.getAll([], clearedFilters, true);
    const { minPrice, maxPrice, data } = getData.data;
    let newPrice = [minPrice, maxPrice];

    setMinPrice(minPrice);
    setMaxPrice(maxPrice);

    if (min) {
      newPrice[0] = Math.max(minPrice, min);
    }

    if (max) {
      newPrice[1] = Math.min(maxPrice, max);
    }

    setPrice(newPrice);
    setData(data);
    setLoading(false);

    if (Object.keys(clearedFilters).length) {
      setApplied([].concat(...Object.values(clearedFilters)));
    } else {
      setApplied([]);
    }
  };

  const filterItems = () => {
    let items = [];

    for (let key in data) {
      if (Number(key) !== 227106) {
        const item = data[key];
        items.push(
          <ProcessFiltersGroup
            key={key}
            data={item}
            applied={applied}
            onChange={toggleFilters}
          />
        );
      }
    }

    items.splice(
      0,
      0,
      <ProcessFiltersGroup
        key={227106}
        data={data[227106]}
        applied={applied}
        onChange={toggleFilters}
      />
    );

    return items;
  };

  const filterTags = () => {
    let items = [];

    if (min && max) {
      items.push(
        <Tag
          key="price"
          text={`Цена: ${priceFormate(min)} - ${priceFormate(max)}`}
          size="smaller"
          tagStyle="grey"
          onClose={() => {
            apply(minPrice, maxPrice, clearedFilters);
          }}
        />
      );
    }

    for (let groupId in clearedFilters) {
      const filterItems = clearedFilters[groupId];

      for (let filterId of filterItems) {
        if (data[groupId] && data[groupId].children[filterId]) {
          const itemData = data[groupId].children[filterId];

          items.push(
            <Tag
              key={itemData.id}
              text={itemData.name}
              size="smaller"
              tagStyle="grey"
              onClose={() => {
                removeFilter(groupId, itemData.id);
              }}
            />
          );
        }
      }
    }

    return items;
  };

  useEffect(() => {
    fetchData();
  }, [ids, filters, min, max]);

  const apply = (min, max, newFilters) => {
    const url = new URL(window.location.href);
    const { searchParams } = url;
    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });

    searchParams.delete("min");
    searchParams.delete("max");

    if (min !== minPrice || max !== maxPrice) {
      searchParams.set("min", min);
      searchParams.set("max", max);
    }

    if (Object.keys(newFilters).length) {
      const filters = toFiltersString(newFilters);
      searchParams.set("filters", filters);
    }

    const href = url.pathname + url.search;
    navigate(href);
  };

  const applyFilters = () => {
    apply(price[0], price[1], newFilters);
    setShow(false);
  };

  const removeFilter = (groupId, filterId) => {
    apply(
      price[0],
      price[1],
      getToggledFilters(Number(groupId), Number(filterId), false)
    );
  };

  const clearFilters = () => {
    setNewFilters({});
    apply(minPrice, maxPrice, {});
    setShow(false);
  };

  return (
    <>
      <div className={`${[c.filters, show ? c.show : null].join(" ")}`}>
        {loading ? (
          <Spinner padding={true} />
        ) : (
          <Container>
            <div className={c.inner}>
              <div className={c.mobileHeader}>
                <div className={c.title}>Фильтры</div>
                <CloseBtn
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </div>
              {Object.keys(clearedFilters).length || min || max ? (
                <div className={c.appliedFilters}>
                  <div className={c.header}>
                    <div className={c.title}>Применено:</div>
                    <Hoverable>
                      <div className={c.clear} onClick={clearFilters}>
                        Очистить
                      </div>
                    </Hoverable>
                  </div>
                  <div className={c.body}>
                    <div className={c.line}>{filterTags()}</div>
                  </div>
                </div>
              ) : null}
              <div className={c.filtersBody}>
                <ArchiveFiltersItem title="Стоимость" open={true}>
                  <PriceSlider
                    min={minPrice}
                    max={maxPrice}
                    value={price}
                    onChange={setPrice}
                  />
                </ArchiveFiltersItem>
                {filterItems()}
              </div>
              <div className={c.filtersBtn}>
                <Button text="Применить" full={true} onClick={applyFilters} />
                <div className={c.mobileBtn}>
                  <Button
                    text="Закрыть"
                    full={true}
                    btnStyle="grey"
                    onClick={() => setShow(false)}
                  />
                </div>
              </div>
            </div>
          </Container>
        )}
      </div>

      <div
        className={[c.fixedBtn, menu.show ? c.hide : null, "icon-filter"].join(
          " "
        )}
        onClick={() => setShow(true)}
        data-count={Object.keys(clearedFilters).length}
      ></div>
    </>
  );
};

export default ArchiveFilters;
