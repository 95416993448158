import React from "react";
import c from "./styles.module.scss";
import Button from "../UI/button";
import { useNavigate } from "react-router-dom";

const PostItem = ({ post }) => {
	const { slug, title, metadata } = post;
	const navigate = useNavigate();
	return (
		<article className={c.post}>
			<div className={c.inner}>
				<img
					alt={slug}
					src={
						metadata?.["post-mobile-image"]?.["imgix_url"] ||
						"/default-image.jpg"
					} // Добавлен fallback для изображения
					className={c.image}
				/>
				<div className={c.brief}>
					<h3 className={c.title}>{title}</h3>
					<p className={c.description}>
						{metadata?.["post-seo-description"] || "Описание отсутствует."}
					</p>
					<Button
						text="Читать"
						afterIcon="arrow-right"
						onClick={() => navigate(`/blog/${slug}`)}
					/>
				</div>
			</div>
		</article>
	);
};

export default PostItem;
