import Modal from "../../modal";
import styles from "./styles.module.scss";

const BnplDetails = ({ showBNPLInfo, setShowBNPLInfo }) => {
    return (
        <Modal show={showBNPLInfo} onClose={() => { setShowBNPLInfo(false); }}>
        <div className={styles.content}>
          <p className={styles.paragraph}>Покупайте наручные часы Casio в рассрочку без комиссий и переплат с сервисом 0-0-4 от BNPL (ТОО «Центр Лизинг»)</p>
          <p className={styles.paragraph}>Почему стоит выбрать рассрочку 0-0-4 от BNPL?</p>
          <ul>
            <li>
              Мгновенное одобрение: Не нужно долго ждать решения.
            </li>
            <li>
              Удобное погашение: Платите картой любого банка.
            </li>
            <li>
              Без переплат: Досрочно погашайте кредит без комиссий и дополнительных затрат.
            </li>
          </ul>
          <p className={styles.paragraph}>Как купить часы Casio в рассрочку?</p>
          <ol>
            <li>
              Выберите товар на сайте g-factory.kz
            </li>
            <li>
              Добавьте в корзину и выберите «Оплатить в рассрочку 0-0-4» на странице оплаты, чтобы перейти на сайт BNPL.
            </li>
            <li>
              Введите номер телефона и ИИН на платформе BNPL.
            </li>
            <li>
              Сохраните данные карты для ежемесячных платежей. Первый платеж будет списан через 30 дней после получения товара.
            </li>
          </ol>
          <p className={styles.paragraph}>Условия покупки в рассрочку 0-0-4</p>
          <ul>
            <li>
              Возраст от 21 до 60 лет.
            </li>
            <li>
              Действующая карта любого банка Республики Казахстан со сроком действия не менее 4 месяцев.
            </li> 
            <li>
              Сумма заказа от 6 000 до 200 000 тенге.
            </li>
            <li>
              Гражданство Республики Казахстан.
            </li>
            <li>
              Номер телефона должен быть зарегистрирован в базе мобильных граждан (БМГ).
            </li>
          </ul>
          <p className={styles.paragraph}> Покупайте с комфортом и наслаждайтесь своими новыми часами Casio уже сегодня!</p>
        </div>
      </Modal>
    );
};

export default BnplDetails;