import Container from "../container"
import c from './styles.module.scss'
import ContactsMap from "../contactsMap"
import { PHONE, EMAIL, ADDRESS, SOCIAL } from "../../config"

const Contacts = () => {
    return (
        <section className={c.section}>
            <Container>
                <div className={c.inner}>
                    <div className={c.map}>
                        <ContactsMap />              
                    </div>
                    <div className={c.info}>
                        <div className={c.item}>
                            <div className={c.title}>Номер телефона</div>
                            {PHONE.map((item, index) => <a key={index} className={c.text} href={`tel:+${item.clean}`}>{item.formatted}</a>)}
                        </div>
                        <div className={c.item}>
                            <div className={c.title}>Email</div>
                            <a className={c.text} href={`mailto:${EMAIL}`}>{EMAIL}</a>
                        </div>
                        <div className={c.item}>
                            <div className={c.title}>Адрес</div>
                            <div className={c.text}>{ADDRESS}</div>
                        </div>
                        <div className={c.item}>
                            <div className={c.title}>Соц. сети</div>
                            <div className={c.links}>
                                <div className={c.line}>
                                    {
                                        SOCIAL.length ?
                                        SOCIAL.map((item, i) => {
                                            return <a key={i} className={c.link} href={item.link} targer="_blank" >{item.name}</a>
                                        }) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Contacts