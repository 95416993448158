import $api from "../http";

export default class JusanPaymentSystemService {
  static async getParams(orderAmount) {
    return $api.get(
      `/jusanPaymentSystem/getStringifiedPaymentParams?orderNumber=${
        Math.floor(Math.random() * 900000000) + 100000000
      }&orderAmount=${orderAmount}&orderDescription=Заказ&orderUserData=`
    );
  }
}
