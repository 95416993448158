import c from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import HoverEffect from '../../hoverEffect'
import Hoverable from '../../hoverable'

const More = ({text = '', link = ''}) => {
    let inner
    if(link) {
        inner = (
            <NavLink to={link} end className={c.more}>
                <span className={c.text}>{text}</span>
                <span className={[c.icon, 'icon-arrow-right'].join(' ')}></span>
            </NavLink>
        )
    } else {
        inner = (
            <div className={c.more}>
                <span className={c.text}>{text}</span>
                <span className={[c.icon, 'icon-arrow-right'].join(' ')}></span>
            </div>
        )
    }

    return (
        <HoverEffect>
            <Hoverable>{inner}</Hoverable>
        </HoverEffect>
    )
}

export default More