import { useRef, useEffect } from "react"
import gsap from 'gsap'
import { TweenLite } from 'gsap/gsap-core'
import { Power4 } from 'gsap'
import c from './styles.module.scss'

gsap.registerPlugin(Power4)

const HoverEffect = ({full = false, children}) => {
    const el = useRef()
    const strength = 20

    const moveMagnet = (e) => {
        var magnetButton = e.currentTarget
        var bounding = magnetButton.getBoundingClientRect()

        TweenLite.to(magnetButton, 1, {
            x: (((e.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
            y: (((e.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
            ease: Power4.easeOut
        })
    }

    useEffect(() => {
        const elem = el.current
        const {innerWidth} = window

        if(innerWidth >= 1200) {
            elem.addEventListener('mousemove', moveMagnet);
            elem.addEventListener('mouseout', function (event) {
                TweenLite.to(event.currentTarget, 1, { x: 0, y: 0, ease: Power4.easeOut })
            })
        }
    }, [])

    return <div className={[c.elem, full ? c.full : null].join(' ')} ref={el}>{children}</div>
}

export default HoverEffect