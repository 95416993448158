const BottomHeaderMenu = [
  {
    url: "/",
    text: "Главная",
    type: "text",
  },
  {
    url: "/products",
    text: "Каталог",
    type: "text",
  },
  {
    url: "/products?filters=225418%3A226525%2C225536",
    text: "Мужские часы",
    type: "text",
  },
  {
    url: "/products?filters=225418%3A225421%2C226525",
    text: "Женские часы",
    type: "text",
  },
  {
    url: "/products?filters=227106%3A230981",
    text: "GENERAL",
    type: "text",
  },
  {
    url: "/products?filters=227106%3A232587",
    path: "/g-shock.svg",
    type: "svg",
  },
  {
    url: "/products?filters=227106%3A233297",
    path: "/baby-g.svg",
    type: "svg",
  },
  {
    url: "/products?filters=227106%3A240396",
    path: "/edifice.svg",
    type: "svg",
  },
  {
    url: "/products?filters=227106%3A260229",
    path: "/protrek.svg",
    type: "svg",
  },
  {
    url: "/products?filters=227106%3A260648",
    path: "/sheen.svg",
    type: "svg",
  },
];

export default BottomHeaderMenu;
