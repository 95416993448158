import c from './styles.module.scss'
import { v4 } from 'uuid'
import { useState } from 'react'
import Hoverable from '../../hoverable'
import HoverEffect from '../../hoverEffect'

const Checkbox = ({value = false, name = '', label = null, onChange = null, required = false, error = false, children, color}) => {
    const id = v4()
    const [isChecked, setIsChecked] = useState(value)

    return (
        <Hoverable>
            <div className={c.wrapper}>
                <HoverEffect>
                    <div className={[c.checkbox, error ? c.error : null].join(' ')}>
                        <input type="checkbox"
                            id={id}
                            name={name}
                            checked={isChecked}
                            onChange={(e) => {
                                setIsChecked(!isChecked)
                                if(onChange) {
                                    onChange(!isChecked)
                                }
                            }}
                            required={required}
                        />
                        <label htmlFor={id} className="icon-check"></label>
                        {color ? <div className={c.color} style={{background: color}}></div> : null}
                    </div>
                </HoverEffect>
                {label || children ? <label htmlFor={id} className={[c.label, color ? c.smallMargin : null].join(' ')}>{label}{children}</label> : null}
            </div>
        </Hoverable>
    )
}

export default Checkbox