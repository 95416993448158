import PageHeader from "../components/pageHeader"
import Contacts from "../components/contacts"
import { Helmet } from "react-helmet"

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Контакты</title>
            </Helmet>
            <PageHeader
                title="Контакты"
                breadcrumbs={[
                    {
                        link: '/',
                        text: 'Контакты'
                    }
                ]}
            />
            <Contacts />
        </>
    )
}

export default ContactPage