const defaultState = {
	show: false,
};

const SET_PROMOCODE_POPUP = "SET_PROMOCODE_POPUP ";

const promocodeReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_PROMOCODE_POPUP:
			return { ...state, show: action.payload };
		default:
			return state;
	}
};

const setPromocodeAction = (payload) => ({
	type: SET_PROMOCODE_POPUP,
	payload,
});

export { promocodeReducer, setPromocodeAction };
