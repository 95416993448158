import c from './styles.module.scss'
import HoverEffect from '../../hoverEffect'
import Hoverable from '../../hoverable'

const CloseBtn = ({onClick, size = 'normal'}) => {
    return (
        <HoverEffect>
            <Hoverable>
                <div
                    className={[c.closeBtn, 'icon-close-alt', c[size]].join(' ')}
                    onClick={onClick}
                ></div>
            </Hoverable>
        </HoverEffect>
    )
}

export default CloseBtn