import DefaultPage from "../components/defaultPage"
import PageHeader from "../components/pageHeader"
import TextBlock from "../components/textBlock"
import { Helmet } from "react-helmet"

const DeliveryPage = () => {
    return (
        <>
            <Helmet>
                <title>Доставка и оплата</title>
            </Helmet>
            <PageHeader
                title="Доставка"
                breadcrumbs={[
                    {
                        link: '/',
                        text: 'Доставка'
                    }
                ]}
            />
            <DefaultPage>
                <TextBlock>
                    <>
                        <p>Доставка товаров, представленных в интернет-магазине нашей компании, осуществляется собственной службой доставки по Алматы и курьерской службой или почтой по всему Казахстану.</p>
                        <h3>Бесплатно, если сумма заказа превышает 50 000 тенге.</h3>
                        <p>Если сумма заказа менее 50 000 тенге, то стоимость доставки рассчитывается индивидуально, в зависимости от удаленности пункта назначения.</p>
                        <h3>Сроки доставки</h3>
                        <ol>
                            <li>Сроки доставки по Алматы могут составить от нескольких часов до 1-2 рабочих дней, после подтверждения заказа менеджером.</li>
                            <li>Сроки доставки по Казахстану могут составить от 2 до 4 рабочих дней, после подтверждения заказа менеджером. Сроки зависят от места назначения.</li>
                        </ol>
                    </>
                </TextBlock>
            </DefaultPage>
        </>
    )
}

export default DeliveryPage