import StatusBlock from "../components/statusBlock"
import Button from "../components/UI/button"
import { Helmet } from "react-helmet"

const ErrorPage = () => {
    return (
        <>
            <Helmet>
                <title>Ошибка</title>
            </Helmet>
            <StatusBlock
                icon="close-alt"
                title="Произошла ошибка"
                text="Что-то пошло не так."
                btn={<Button text="На главную" link="/" btnStyle="grey"/>}
            />
        </>
    )
}

export default ErrorPage