import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PostPreview from "../components/postPreview";
import CosmicController from "../controllers/cosmicController";
import Spinner from "../components/spinner"; // Импортируем спиннер
import { Helmet } from "react-helmet-async";

const PostPage = () => {
	const { slug } = useParams();
	const location = useLocation(); // Используем useLocation для получения текущего пути

	const [post, setPost] = useState(null); // Изменяем начальное состояние на null
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const getPostBySlug = async () => {
		setLoading(true);
		try {
			const { data } = await CosmicController.getPostBySlug(slug);
			setPost(data);
		} catch (error) {
			setError("При подгрузке поста произошла ошибка");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPostBySlug();
	}, [slug]);

	if (loading) {
		return <Spinner />;
	}

	if (error) {
		return <p>{error}</p>;
	}

	// Проверяем, есть ли данные у поста, чтобы не рендерить пустые теги
	if (!post || !post.metadata) {
		return null;
	}

	return (
		<>
			{/* Делаем проверку перед рендером мета-тегов */}
			

			{/* Компонент превью поста */}
			<PostPreview post={post} />
		</>
	);
};

export default PostPage;
