import React, { useCallback, useState } from "react";
import c from "./styles.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { watchAttributes, watchFunctions } from "../../config";

const attributeContent = {
	Пол: {
		level: "h6", // Изменил уровень на "h6"
		title: watchAttributes.gender.title,
		info: watchAttributes.gender.info,
	},
	"Тип механизма": {
		level: "h6",
		title: watchAttributes.mechanismType.title,
		info: watchAttributes.mechanismType.info,
	},
	"Источник питания": {
		level: "h6",
		title: watchAttributes.powerSource.title,
		info: watchAttributes.powerSource.info,
	},
	"Тип циферблата": {
		level: "h6",
		title: watchAttributes.dialType.title,
		info: watchAttributes.dialType.info,
	},
	Подсветка: {
		level: "h6",
		title: watchAttributes.backlight.title,
		info: watchAttributes.backlight.info,
	},
	"Форма корпуса": {
		level: "h6",
		title: watchAttributes.caseShape.title,
		info: watchAttributes.caseShape.info,
	},
	"Материал корпуса": {
		level: "h6",
		title: watchAttributes.caseMaterial.title,
		info: watchAttributes.caseMaterial.info,
	},
	"Цвет корпуса": {
		level: "h6",
		title: watchAttributes.caseColor.title,
		info: watchAttributes.caseColor.info,
	},
	"Цвет ремешка/браслета": {
		level: "h6",
		title: watchAttributes.strapColor.title,
		info: watchAttributes.strapColor.info,
	},
	Стекло: {
		level: "h6",
		title: watchAttributes.glass.title,
		info: watchAttributes.glass.info,
	},
	"Материал ремешка/браслета": {
		level: "h6",
		title: watchAttributes.strapMaterial.title,
		info: watchAttributes.strapMaterial.info,
	},
};

const functionContent = {
	"Будильник": {
		level: "h6",
		title: watchFunctions.alarm.title,
		info: watchFunctions.alarm.info,
	},
	"День недели": {
		level: "h6",
		title: watchFunctions.weekDay.title,
		info: watchFunctions.weekDay.info,
	},
	"Компас": {
		level: "h6",
		title: watchFunctions.compass.title,
		info: watchFunctions.compass.info,
	},
	"Мировое время": {
		level: "h6",
		title: watchFunctions.worldTime.title,
		info: watchFunctions.worldTime.info,
	},
	"Секундомер": {
		level: "h6",
		title: watchFunctions.stopwatch.title,
		info: watchFunctions.stopwatch.info,
	},
	"Таймер": {
		level: "h6",
		title: watchFunctions.timer.title,
		info: watchFunctions.timer.info,
	},
	"Термометр": {
		level: "h6",
		title: watchFunctions.thermometer.title,
		info: watchFunctions.thermometer.info,
	},
	"Формат времени 12/24": {
		level: "h6",
		title: watchFunctions.timeFormat.title,
		info: watchFunctions.timeFormat.info,
	},
	"Число месяца": {
		level: "h6",
		title: watchFunctions.dayOfMonth.title,
		info: watchFunctions.dayOfMonth.info,
	},
	"Водонепроницаемость WR100": {
		level: "h6",
		title: watchFunctions.waterResistance.title,
		info: watchFunctions.waterResistance.info,
	},
	"Bluetooth": {
		level: "h6",
		title: watchFunctions.bluetooth.title,
		info: watchFunctions.bluetooth.info,
	},
	"Хронограф": {
		level: "h6",
		title: watchFunctions.chronograph.title,
		info: watchFunctions.chronograph.info,
	},
};

const Item = ({ title, value, large }) => {
	const location = useLocation();
	const rightPathName = location.pathname.startsWith("/product");

	const [isAttributesInfoShowed, setIsAttributesInfoShowed] = useState(false);
const [isFunctionsInfoShowed, setIsFunctionsInfoShowed] = useState({});



	const showInfo = useCallback(() => {
		setIsAttributesInfoShowed(!isAttributesInfoShowed);
	}, [isAttributesInfoShowed]);

const showFunctionsInfo = (func) => {
	setIsFunctionsInfoShowed((prev) => ({
		...prev,
		[func]: !prev[func],
	}));
};



	let arr = [];
	let functions = [];
	if (title === "Функции") {
		arr.push(value.split("|"));
		let newValue = "";
		for (let func of arr[0]) {
			newValue = func.trim();
			functions.push(newValue);
		}
	}


	return (
		<>
			{title !== "Функции" ? (
				<>
					<div className={[c.item, large ? c.large : null].join(" ")}>
						<div className={c.title}>{title}</div>
						<div className={c.line}></div>

						{rightPathName &&
							(isAttributesInfoShowed ? (
								<>
									<div className={c.value}>{value}</div>
									<IoIosCloseCircleOutline
										className={c.icon}
										onClick={showInfo}
									/>
								</>
							) : (
								<>
									<div className={c.value}>{value}</div>
									<AiOutlineQuestionCircle
										className={c.icon}
										onClick={showInfo}
									/>
								</>
							))}
					</div>

					<div className={isAttributesInfoShowed ? c.open : c.info}>
						{attributeContent[title] && (
							<>
								{React.createElement(
									attributeContent[title].level,
									{},
									attributeContent[title].title
								)}
								<div
									dangerouslySetInnerHTML={{
										__html: attributeContent[title].info,
									}}
								/>
							</>
						)}
					</div>
				</>
			) : (
				<>
					<div className={c.item}>
						<div className={c.functions}>
							{functions.map((func, index) => {
								return (
									<div key={index} className={c.functionItem}>
										{func !== "Нет"? (
											!isFunctionsInfoShowed[func] ? (
												<div className={c.functionText}>
													<span>{func}</span>

													<AiOutlineQuestionCircle
														className={c.icon}
														onClick={() => showFunctionsInfo(func)}
													/>
												</div>
											) : (
												<div className={c.functionText}>
													<span>{func}</span>

													<IoIosCloseCircleOutline
														className={c.icon}
														onClick={() => showFunctionsInfo(func)}
													/>
												</div>
											)
										) : (
											<span>{func}</span>
										)}
										<div
											className={isFunctionsInfoShowed[func] ? c.open : c.info}
										>
											{functionContent[func] && (
												<>
													{React.createElement(
														functionContent[func].level,
														{},
														functionContent[func].title
													)}
													<div
														dangerouslySetInnerHTML={{
															__html: functionContent[func].info,
														}}
													/>
												</>
											)}
										</div>
									</div>
								);
							})}
						</div>

						
					</div>
				</>
			)}
		</>
	);
};

const ParamsList = ({ items, two = false }) => {
	return (
		<div className={[c.list, two ? c.two : null].join(" ")}>
			<div className={c.inner}>
				{items.map((item, index) => (
					<Item key={index} {...item} />
				))}
			</div>
		</div>
	);
};

export default ParamsList;
