import { NavLink } from 'react-router-dom'
import c from './styles.module.scss'
import { priceFormate } from '../../utils/price'
import { useSelector } from 'react-redux'

const CartBtnMobile = () => {
    const {totalPrice, totalQuantity} = useSelector(store => store.cart)

    return (
        <NavLink
            to="/cart"
            end
            className={c.cartBtn}
        >
            <div className={[c.icon, 'icon-cart'].join(' ')} data-count={totalQuantity}></div>
            <div className={c.price}>{priceFormate(totalPrice)}</div>
        </NavLink>
    )
}

export default CartBtnMobile