import c from './styles.module.scss'
import Button from '../UI/button'
import { priceFormate } from '../../utils/price'
import { quantityFormate } from '../../utils/quantity'
import ProductsList from '../productsList'
import { useState } from 'react'
import CartController from '../../controllers/cartController'

const Meta = ({icon, text}) => {
    return (
        <div className={c.metaItem}>
            <span className={[c.icon, 'icon-' + icon].join(' ')}></span>
            <span className={c.text}>{text}</span>
        </div>
    )
}

const OrdersListItem = ({data}) => {
    const {id, price, quantity, comment, address, phone, createdAt, cart_products} = data
    const [show, setShow] = useState(false)
    const [added, setAdded] = useState(false)
    const meta = [
        {
            icon: 'calendar',
            text: new Date(createdAt).toLocaleDateString()
        },
        {
            icon: 'place',
            text: address
        },
        {
            icon: 'phone',
            text: phone
        },
        {
            icon: 'inbox',
            text: quantityFormate(quantity)
        },
    ]

    const addProducts = () => {
        setAdded(true)
        for(let item of cart_products) {
            CartController.addToCart(item.productId, item.quantity)
        }
    }

    return (
        <div className={c.order}>
            <div className={c.info}>
                <div className={c.content}>
                    <div className={c.title}><span>#{id} - </span>{priceFormate(price)}</div>
                    {comment ? <div className={c.comment}>{comment}</div> : null}
                    <div className={c.meta}>
                        <div className={c.line}>
                            {
                                meta.map((item, i) => item.text ? <Meta key={i} {...item}/> : null)
                            }
                        </div>
                    </div>
                    {show ? <div className={c.productsList}><ProductsList items={cart_products}/></div> : null}
                    <div className={c.btns}>
                        <Button text={show ? 'Скрыть корзину' : 'Показать корзину'} size="smaller" btnStyle="grey" onClick={() => {setShow(!show)}}/>
                        <Button text="Добавить в корзину" size="smaller" disabled={added} onClick={addProducts}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrdersListItem