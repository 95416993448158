import { useState, useEffect } from "react";
import CosmicController from "../controllers/cosmicController";

const useCosmic = (limit = 10) => {
	const [posts, setPosts] = useState([]);
	const [page, setPage] = useState(1);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const getPosts = async () => {
		setLoading(true);
		try {
			const { data, total } = await CosmicController.getPosts(limit, page);
			if (data.length < limit || posts.length + data.length >= total) {
				setHasMore(false); // Больше постов нет
			}
			setPosts((prevPosts) => [...prevPosts, ...data]);
		} catch (error) {
			setError("При подгрузке постов произошла ошибка");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPosts();
	}, [page]);

	const loadMorePosts = () => {
		if (hasMore && !loading) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	return { posts, error, loading, loadMorePosts, hasMore };
};

export default useCosmic;
