import c from "./styles.module.scss";
import Container from "../container";
import CartProducts from "../cartProducts";
import OrderForm from "../orderForm";
import CartSidebar from "../cartSidebar";
import { useSelector } from "react-redux";
import { quantityFormate } from "../../utils/quantity";

const BodyItem = ({ title, after = "", children, id }) => {
  return (
    <div className={c.item} id={id}>
      <div className={c.title}>
        {title}
        {after ? <span>{after}</span> : null}
      </div>
      <div className={c.content}>{children}</div>
    </div>
  );
};

const CartInfo = () => {
  const { totalQuantity } = useSelector((store) => store.cart);

  return (
    <section className={c.section}>
      <Container>
        <div className={c.inner}>
          <div className={c.body}>
            <BodyItem
              title="Товары в корзине"
              after={` (${quantityFormate(totalQuantity)})`}
            >
              <CartProducts />
            </BodyItem>
            {totalQuantity ? (
              <BodyItem title="Оформление заказа" id="orderForm">
                <OrderForm />
              </BodyItem>
            ) : null}
          </div>
          <div className={c.aside}>
            <CartSidebar
              onClick={() => {
                document.getElementById("orderForm").scrollIntoView();
              }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CartInfo;
