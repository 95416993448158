import c from './styles.module.scss'
import Container from '../container'
import PagesSidebar from '../pagesSidebar'

const DefaultPage = ({children}) => {
    return (
        <section className={c.section}>
            <Container>
                <div className={c.inner}>
                    <div className={c.body}>{children}</div>
                    <div className={c.aside}>
                        <PagesSidebar />
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default DefaultPage