const TopHeaderMenu = [
	{
		url: "/products",
		text: "Каталог",
	},
	{
		url: "/delivery",
		text: "Доставка",
	},
	{
		url: "/service",
		text: "Сервис центр",
	},
	{
		url: "/faq",
		text: "FAQ",
	},
	{
		url: "/contact",
		text: "Контакты",
	},
	{
		url: "/offer",
		text: "Публичная оферта",
	},
	{
		url: "/blog",
		text: "Блог",
	},
];

export default TopHeaderMenu