import c from "./styles.module.scss";
import Container from "../../components/container";
import TopHeaderMenu from "../../routes/topHeaderMenu";
import BottomHeaderMenu from "../../routes/bottomHeaderMenu";
import { NavLink } from "react-router-dom";
import Logo from "../../components/logo";
import HeaderBtns from "../../components/headerBtns";
import { PHONE, EMAIL, SOCIAL } from "../../config";
import SearchField from "../../components/searchField";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderBtnsMobile from "../../components/headerBtnsMobile";
import MobileMenuBtn from "../../components/mobileMenuBtn";
import MobileMenu from "../../components/mobileMenu";

const Header = () => {
	const header = useRef();
	const [show, setShow] = useState(false);
	const cart = useSelector((store) => store.cart);

	const checkScroll = () => {
		const scroll = window.pageYOffset;
		const height = header.current.clientHeight;

		if (scroll > height) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScroll);

		return () => {
			window.removeEventListener("scroll", checkScroll);
		};
	}, []);

	return (
		<>
			<header ref={header} className={c.header}>
				<div className={c.topHeader}>
					<Container>
						<div className={c.inner}>
							<nav>
								<ul>
									{TopHeaderMenu.map((item, i) => (
										<li key={i}>
											<NavLink
												to={item.url}
												end
												className={({ isActive }) =>
													isActive ? c.activeLink : null
												}
											>
												{item.text}
											</NavLink>
										</li>
									))}
								</ul>
							</nav>
							<div className={c.social}>
								{SOCIAL.length
									? SOCIAL.map((item, i) => {
											return (
												<a href={item.link} key={i}>
													<item.Icon />
												</a>
											);
									  })
									: null}
							</div>
							<div className={c.contacts}>
								<a
									href={`tel:+${PHONE[0].clean}`}
									className={[c.contactsItem, "icon-phone"].join(" ")}
								>
									{PHONE[0].formatted}
								</a>
								<a
									href={`mailto:${EMAIL}`}
									className={[c.contactsItem, "icon-email"].join(" ")}
								>
									{EMAIL}
								</a>
							</div>
						</div>
					</Container>
				</div>
				<div className={c.middleHeader}>
					<Container>
						<div className={c.inner}>
							<div className={c.logoWrapper}>
								<Logo />
							</div>

							<div className={c.searchWrapper}>
								<SearchField size="smaller" />
							</div>

							<div className={c.rightBlock}>
								<HeaderBtns />
							</div>
						</div>
					</Container>
				</div>
				<div className={c.bottomHeader}>
					<Container>
						<div className={c.inner}>
							<nav>
								<ul>
									{BottomHeaderMenu.map((item, i) => (
										<li
											key={i}
											onClick={() => {
												sessionStorage.setItem("gf_page", 1);
											}}
										>
											<NavLink
												to={item.url}
												end
												className={({ isActive }) =>
													isActive ? c.activeLink : null
												}
											>
												{item.type === "text" ? (
													item.text
												) : (
													<img
														style={{ cursor: "pointer" }}
														src={item.path}
														alt={item.url}
													/>
												)}
											</NavLink>
										</li>
									))}
								</ul>
							</nav>
						</div>
					</Container>
				</div>
				<div className={c.mobileHeader}>
					<Container>
						<div className={c.wrapper}>
							<div className={c.inner}>
								<div className={c.leftBlock}>
									<Logo />
								</div>

								<div className={c.rightBlock}>
									<HeaderBtnsMobile />
									<MobileMenuBtn />
								</div>
							</div>
							<div className={c.searchWrapper}>
								<SearchField size="larger" />
							</div>
						</div>
					</Container>
				</div>
			</header>

			<MobileMenu />

			<div className={[c.fixedBtns, show ? c.show : null].join(" ")}>
				<NavLink
					to="/cart"
					end
					className={[c.link, c.icon, "icon-cart", c.bg].join(" ")}
					data-count={cart.totalQuantity}
				></NavLink>
				<NavLink to="/" end className={c.link}>
					Главная
				</NavLink>
				<NavLink to="/products" end className={c.link}>
					Каталог
				</NavLink>
			</div>
		</>
	);
};

export default Header;
