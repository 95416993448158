import React from "react";
import c from "./styles.module.scss";


const CartItem = ({ title, value, large }) => {
	

	return (
		<>
			<div className={[c.item, large ? c.large : null].join(" ")}>
				<div className={c.title}>{title}</div>
				<div className={c.line}></div>

				<div className={c.value}>{value}</div>
			</div>
		</>
	);
};

const CartParamsList = ({ items, two = false }) => {
	return (
		<div className={[c.list, two ? c.two : null].join(" ")}>
			<div className={c.inner}>
				{items.map((item, index) => (
					<CartItem key={index} {...item} />
				))}
			</div>
		</div>
	);
};

export default CartParamsList;
