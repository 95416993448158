import c from "./styles.module.scss";
import Input from "../UI/input";
import { useState } from "react";
import Button from "../UI/button";
import Checkbox from "../UI/checkbox";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Message from "../message";
import CartController from "../../controllers/cartController";
import { useNavigate } from "react-router-dom";
import { dispatchStore } from "../../store";
import {
  setFormDataAction,
  setFormErrorAction,
  setFormErrorsAction,
  setFormIsCorrectAction,
} from "../../store/cartReducer";

const OrderForm = () => {
  const { totalQuantity, added } = useSelector((store) => store.cart);
  const { isAuth, user } = useSelector((store) => store.auth);
  const { formData, formErrors, formError } = useSelector((store) => store.cart);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const onSend = async (e) => {
    e.preventDefault();

    if (
      !formErrors.name &&
      !formErrors.phone &&
      !formErrors.agree &&
      !formErrors.address &&
      formData.name &&
      formData.phone &&
      formData.address &&
      formData.agree
    ) {
      dispatchStore(setFormIsCorrectAction(true));

      const data = { ...formData, added };
      data.phone = data.phone.replaceAll(/[^0-9]/gm, "");

      if (isAuth) {
        data.id = user.id;
      }

      setIsLoading(true);
      const send = await CartController.send(data);

      if (send.success) {
        /*  await CartController.clearCart() */

        localStorage.setItem("orderName", formData.name);
        localStorage.setItem("orderPhone", formData.phone);
        localStorage.setItem("orderAddress", formData.address);

        navigate("/success");
      } else {
        setIsLoading(false);
        dispatchStore(setFormErrorAction(send.message));
      }
    } else {
      if (!formData.agree) {
        dispatchStore(setFormErrorsAction({ ...formErrors, agree: true }));
        dispatchStore(setFormIsCorrectAction(false));
      }
    }
  };

  return (
    <form className={c.form}>
      {formError ? (
        <div className={c.full}>
          <Message status="error" text={formError} onClose={() =>  dispatchStore(setFormErrorAction(""))} />
        </div>
      ) : null}
      <Input
        label="Ваше имя"
        required={true}
        placeholder="Введите имя"
        value={formData.name}
        onChange={(e) => {
          dispatchStore(
            setFormDataAction({ ...formData, name: e.target.value })
          );
        }}
        icon="user"
        validation={{
          type: "name",
          onChange: (error) => {
            dispatchStore(setFormErrorsAction({ ...formErrors, name: error }));
          },
        }}
      />

      <Input
        label="Номер телефона"
        required={true}
        placeholder="Введите номер"
        value={formData.phone}
        onChange={(e) => {
          dispatchStore(
            setFormDataAction({ ...formData, phone: e.target.value })
          );
        }}
        icon="phone"
        validation={{
          type: "phone",
          onChange: (error) => {
            dispatchStore(setFormErrorsAction({ ...formErrors, phone: error }));
          },
        }}
      />

      <div className={c.full}>
        <Input
          label="Адрес доставки"
          placeholder="Введите адрес"
          required={true}
          value={formData.address}
          icon="place"
          onChange={(e) => {
            dispatchStore(
              setFormDataAction({ ...formData, address: e.target.value })
            );
          }}
          validation={{
            type: "address",
            onChange: (error) => {
              dispatchStore(
                setFormErrorsAction({ ...formErrors, address: error })
              );
            },
          }}
        />
      </div>

      <div className={c.full}>
        <Input
          label="Комментарии к заказу"
          placeholder="Введите комментарии"
          value={formData.comment}
          icon="chat"
          onChange={(e) => {
            dispatchStore(
              setFormDataAction({ ...formData, comment: e.target.value })
            );
          }}
        />
      </div>

      <div className={c.full}>
        <Checkbox
          name="agree"
          required={true}
          value={formData.agree}
          error={formErrors.agree}
          onChange={(value) => {
            dispatchStore(setFormErrorsAction({ ...formErrors, agree: false }));
            dispatchStore(setFormDataAction({ ...formData, agree: value }));
          }}
        >
          <p>
            Я принимаю условия{" "}
            <NavLink end to="privacy-policy">
              политики конфиденциальности
            </NavLink>
            .
          </p>
        </Checkbox>
      </div>

      <div className={c.full}>
        {totalQuantity ? (
          <Button
            text="Отправить заявку"
            full={true}
            onClick={onSend}
            loading={isLoading}
          />
        ) : (
          <Button text="Корзина пуста" full={true} disabled={true} />
        )}
      </div>
    </form>
  );
};

export default OrderForm;
