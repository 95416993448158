import React, { useEffect, useState } from "react";
import c from "./styles.module.scss";

const Pagination = ({
	active,
	from,
	to,
	onPreviousPage,
	onNextPage,
	onChoosePage,
}) => {
	const [visibleRange, setVisibleRange] = useState(getVisibleRange());

	useEffect(() => {
		function handleResize() {
			setVisibleRange(getVisibleRange());
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	function getVisibleRange() {
		return window.innerWidth < 768 ? 3 : 5; // Adjust the breakpoint and range as needed
	}

	const items = [];

	let start = Math.max(1, active - Math.floor(visibleRange / 2));
	let end = Math.min(to, start + visibleRange - 1);

	if (end > to) {
		end = to;
		start = Math.max(1, end - visibleRange + 1);
	}

	// Add button for the first page

	if (active >= 4) {
		items.push(
			<div key={0} className={c.item} onClick={() => onChoosePage(from)}>
				{from}
			</div>
		);
	}

	// Add left ellipsis if needed
	if (start > 2) {
		items.push(
			<div key={1} className={c.elipsis}>
				...
			</div>
		);
	}

	for (let i = start; i <= end; i++) {
		items.push(
			<div
				key={i}
				className={[c.item, i === active ? c.active : null].join(" ")}
				onClick={() => onChoosePage(i)}
			>
				{i}
			</div>
		);
	}

	// Add right ellipsis if needed
	if (end < to) {
		items.push(
			<div key={1000} className={c.elipsis}>
				...
			</div>
		);
	}

	// Add button for the last page
	if (to > end) {
		items.push(
			<div key={to} className={c.item} onClick={() => onChoosePage(to)}>
				{to}
			</div>
		);
	}

	return (
		<div className={c.pagination}>
			{active !== from ? (
				<div
					className={[c.item, c.arrow, "icon-arrow-left"].join(" ")}
					onClick={onPreviousPage}
				></div>
			) : null}
			{items}
			{active !== to ? (
				<div
					className={[c.item, c.arrow, "icon-arrow-right"].join(" ")}
					onClick={onNextPage}
				></div>
			) : null}
		</div>
	);
};

export default Pagination;
