import { useEffect } from "react";
import c from "./styles.module.scss";
import CloseBtn from "../UI/closeBtn";

const Modal = ({ show = false, children = null, onClose = null }) => {
	useEffect(() => {
		if (show) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = "";
		}

		return () => {
			document.documentElement.style.overflow = "";
		};
	}, [show]);

	if (show) {
		return (
			<div className={[c.modal, c.show].join(" ")}>
				<div className={c.blackboard} onClick={onClose}></div>
				<div className={c.wrapper}>
					<div className={c.content}>
						<div className={c.closeBtn}>
							<CloseBtn onClick={onClose} />
						</div>
						<div className={c.inner}>{children}</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Modal;
