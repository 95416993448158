import React from 'react';
import styles from "./styles.module.scss"
import Contacts from "../../components/contacts"
import Container from "../container"
const ContactsForHomePage = () => {
    return (
        <div className={styles.wrapper}>
<Container>

    <h2>
        Cвязаться с нами
    </h2>
    <Contacts/>
</Container>
        </div>
    );
};

export default ContactsForHomePage;