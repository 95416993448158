import { dispatchStore, getStoreState } from "../store";
import {
	setAddedAction,
	setProductsAction,
	setPriceAction,
	setPurePriceAction,
	setQuantityAction,
	setDiscountAction,
	setPromocodeAction,
} from "../store/cartReducer";
import ProductController from "./productController";
import CartService from "../services/cartService";

import "react-toastify/dist/ReactToastify.css";

export default class CartController {
	static async setCart(added, setStorage = true) {
		if (setStorage) localStorage.setItem("cart", JSON.stringify(added));

		await dispatchStore(setAddedAction(added));
		await this.updateProducts();
	}
	static isItemInCart(itemId) {
		const { cart } = getStoreState();
		const { added } = cart;

		return added.some((item) => item.id === itemId);
	}
	static async addToCart(id, quantity = 1) {
		try {
			const { cart } = getStoreState();
			let added = [...cart.added];
			let found = false;

			for (let item of added) {
				if (item.id === id) {
					item.quantity += quantity;
					found = true;
				}
			}

			// if(!found){
			// 	added.push({ id, quantity });
			// 	await this.setCart(added);

			// }

			added.push({ id, quantity });
			await this.setCart(added);

			// this.setCart(added);
		} catch (error) {
			console.log("Ошибка при добавлении товара в корзину");
		}
	}

	static async updateProducts() {
		const { cart } = getStoreState();
		const { added } = cart;
		let products = Object.assign(cart.products);
		let ids = [];

		if (added.length) {
			for (let item of added) {
				const { id } = item;

				if (!products[id]) {
					ids.push(id);
				}
			}

			if (ids.length) {
				const getProducts = await ProductController.getByIds(ids);

				if (getProducts.success) {
					for (let item of getProducts.data.rows) {
						products[item.id] = item;
					}

					await dispatchStore(setProductsAction(products));
				}
			}
		}

		await this.updateCart();
	}
	static async updateCart() {
		const { cart } = getStoreState();
		const { added, products, discount } = cart;
		let multiplier = discount ? 1 - discount * 0.01 : 1;
		let totalPrice = 0;
		let purePrice = 0;
		let totalQuantity = 0;

		for (let item of added) {
			const { id, quantity } = item;
			const product = products[id];

			if (product) {
				const price = Math.round(product.price * multiplier);

				totalQuantity += quantity;
				totalPrice += price * quantity;
				purePrice += product.price * quantity;
			}
		}

		await dispatchStore(setPriceAction(totalPrice));
		await dispatchStore(setPurePriceAction(purePrice));
		await dispatchStore(setQuantityAction(totalQuantity));
	}

	static async setDiscount(discount) {
		await dispatchStore(setDiscountAction(discount));
		await this.updateCart();
	}

	static async verifyPromocode(promocode) {
		try {
			const { success, message, data } = await CartService.verifyPromocode(
				promocode
			).then(res =>  res.data);
			
			return {
				success,
				message,
				data,
			};
		} catch (error) {
			console.log("Ошибка верификации промокода: ", error);
		}
	}
	static async applyPromocode(promocode) {
		try {
			const { success, message, data } = await this.verifyPromocode(promocode);

			if (success) {
				const { cart } = getStoreState();
				const { discount: currentDiscount } = cart;
				const promocodeDiscount = data.discountPercentage;
				let updatedDiscount = currentDiscount + promocodeDiscount;
				console.log(message);
				await dispatchStore(setPromocodeAction({name: promocode, discountPercentage: data.discountPercentage}))
				await this.setDiscount(updatedDiscount);
				
				console.log(message);
			} else {
				console.log(message);
			}
			return {success, message, discountPercentage: data.discountPercentage}
		} catch (error) {
			console.log("Ошибка применения промокода: ", error);
		}
	}

	static async clearCart() {
		await dispatchStore(setAddedAction([]));
		await dispatchStore(setProductsAction({}));
		await dispatchStore(setPriceAction(0));
		await dispatchStore(setPurePriceAction(0));
		await dispatchStore(setQuantityAction(0));

		localStorage.removeItem("cart");
	}
	static async setQuantity(id, quantity) {
		const { cart } = getStoreState();
		let added = [...cart.added];

		added = added.map((item) => {
			if (item.id === id) {
				item.quantity = quantity;
			}

			return item;
		});

		await this.setCart(added);
	}
	static async removeItem(id) {
		const { cart } = getStoreState();
		let added = [...cart.added];

		if (added.length) {
			added = added.filter((item) => item.id !== id);
		}

		await this.setCart(added);
	}

	static async send(data) {
		const result = {
			success: false,
			message: "",
		};

		try {
			await CartService.send(data);
			result.success = true;
			return result;
		} catch (e) {
			result.message = "Ошибка при отправке данных";
			return result;
		}
	}
	static async userOrders(id) {
		const result = {
			success: false,
			message: "",
		};

		try {
			const data = await CartService.userOrders(id);
			result.success = true;
			result.data = data;
			return result;
		} catch (e) {
			result.message = "Ошибка при отправке данных";
			return result;
		}
	}
}
