import DefaultPage from "../components/defaultPage"
import PageHeader from "../components/pageHeader"
import Accords from "../components/accords"
import { Helmet } from "react-helmet"

const FaqPage = () => {
    const data = [
			{
				title: "Сколько стоит доставка по Алматы? Какие сроки?",
				content: (
					<p>
						Курьерская доставка по Алматы бесплатна при сумме заказа от 50.000
						тг. При заказе до 50.000 тг. стоимость доставки  рассчитывается индивидуально, в зависимости от удаленности пункта назначения.
						<br />
						Если заказ создан в первой половине дня, мы доставим его в этот же
						день, если заказ поступил позднее, мы привезем его на следующий день
						в удобное вам время. Доставка осуществляется 5 дней в неделю с
						понедельника по пятницу.
					</p>
				),
			},
			{
				title: "Сколько стоит доставка по Казахстану?",
				content: (
					<p>
						Курьерская доставка по Казахстану бесплатна при сумме заказа свыше
						50.000 тг. При заказе до 50.000 тг. стоимость доставки  рассчитывается индивидуально, в зависимости от удаленности пункта назначения.
					</p>
				),
			},
			{
				title: "Оригинальность продукции",
				content: (
					<p>
						Интернет-магазин G-factory.kz является официальным магазином CASIO
						на территории Республики Казахстан. Цены, установленные на все
						модели CASIO являются официальными розничными ценами и полностью
						отвечают ценовой политике CASIO Kazakhstan. Со всеми нашими часами
						вы получаете официальную гарантию от Casio нового образца на 2 года.{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.casioblog.ru/kak-otlichit-nastoyashhie-chasy-casio-ot-poddelki"
						>
							Дополнительно мы рекомендуем ознакомиться вам с материалами:
						</a>{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.casioblog.ru/kak-otlichit-nastoyashhie-chasy-casio-ot-poddelki"
						>
							как отличить оригинал от подделки,
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.casioblog.ru/fake-shops"
						>
							{" "}
							как распознать «серый» интернет-магазин Casio
						</a>{" "}
						и{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.casioblog.ru/cheap-fake-watches"
						>
							почему часы Casio не могут стоить в 2 раза дешевле официальной
							цены.
						</a>
					</p>
				),
			},
			{
				title: "Официальный авторизированный сервисный центр CASIO",
				content: (
					<p>
						Мы рекомендуем менять батарейки в часах Casio, а также заказывать или менять
						ремешки и иные комплектующие только в официальном авторизированном сервисном
						центре Casio, который находится по адресу: г. Алматы, «G-Service», ул. Муканова, 70. 
						Контакты сервисного центра: +7 707 111 64 66
					</p>
				),
			},
			{
				title: "Как заказать ремешок или поменять батарейку?",
				content: (
					<p>
						По замене ремешка или батарейки в часах, вы можете обратиться
						в наш официальный авторизированный сервисный центр, который
						находится по адресу: г. Алматы, ул. Муканова, 70 или написать нашему мастеру по номеру: +7 707 111 64 66
					</p>
				),
			},
			{
				title: "Можно ли вернуть часы, если они не понравились?",
				content: (
					<p>
						Возврат возможен, но только в случае, если с момента покупки прошло
						менее 14 дней, имеется полная комплектация и нет никаких внешних
						дефектов (царапин, сколов и т.д.). Решение о возврате принимается
						только после осмотра часов нашим специалистом сервисного центра.
					</p>
				),
			},
			{
				title: "Можно ли у вас отполировать часы?",
				content: (
					<p>
						Да, вы можете отполировать свои часы, обратившись в наш
						авторизированный сервисный центр по адресу Муканова 70 (вход с
						магазина G-Square)
					</p>
				),
			},
			{
				title:
					"Если я уже сделал заказ на самовывоз, можно ли переоформить на доставку?",
				content: (
					<p>
						Да, вы можете переоформить ваш заказ. С условиями доставки вы можете
						ознакомиться в разделе "О доставке". В случае, если ваш заказ не
						попадает под критерии бесплатной доставки (заказ на сумму менее 50
						000 тенге), мы рассчитаем стоимость и вышлем вам удалённый счёт на
						оплату с разницей в стоимости. Более подробно вы можете уточнить
						конкретно ваш случай, связавшись с нашим менеджером по номеру
						телефона: +7 777 393 80 22
					</p>
				),
			},
			{
				title: "Можно ли у вас отполировать минеральное/сапфировое стекло?",
				content: (
					<p>
						К сожалению, минеральные, сапфировые и хезалитовые стёкла очень
						хрупкие и гарантировать, что оно не лопнет при полировке мы не
						можем, поэтому мы не занимаемся полировкой стёкол, а производим
						полную их замену.
					</p>
				),
			},
			{
				title: "Могу ли я приобрести у вас сертификат для подарка?",
				content: (
					<p>
						К сожалению, подарочных сертификатов у нас нет, поэтому мы не можем
						предоставить такую возможность.
					</p>
				),
			},
			{
				title:
					"Если поломка часов попадает под гарантийный случай, то сколько будет длиться замена/ремонт моего изделия?",
				content: (
					<p>
						Мы осуществляем гарантийный ремонт/замену часов в течении 7-14
						рабочих дней после диагностики и выявления поломки. В зависимости от
						сложности ремонта, сроки могут варьироваться.
					</p>
				),
			},
			{
				title:
					"Вы ремонтируете часы оригинальными запчастями? Если да, то можно ли их у вас заказать отдельно?",
				content: (
					<p>
						Да, мы используем только оригинальные запчасти, которые заказываем
						напрямую от производителя. Заказать детали можно по полной
						предоплате. Длительность ожидания поставки варьируется от 1 до 4
						месяцев включительно.
					</p>
				),
			},
			{
				title: "У вас есть скидки в День Рождения?",
				content: (
					<p>
						-Да, у нас действует скидка 10%. Вы можете ей воспользоваться за 2
						дня до дня рождения, в день рождения и 2 дня после дня рождения.
					</p>
				),
			},
			{
				title: "Какие у вас есть способы оплаты?",
				content: (
					<p>
						Вы можете оплатить картой на сайте, картой через POS-терминалы на
						розничных точках, Halyk QR и Kaspi QR.
					</p>
				),
			},
			{
				title: "У вас есть доставка к конкретному времени?",
				content: (
					<p>
						Да, мы можем оформить доставку в рабочие часы по городу Алматы.
						Более подробно вы можете уточнить этот вопрос, связавшись с нашим
						менеджером по номеру телефона: +7 777 393 80 22. В остальных
						регионах такая услуга не предоставляется.
					</p>
				),
			},
			{
				title: "Можно ли примерить часы перед покупкой?",
				content: (
					<p>
						Да, вы можете примерить и выбрать аксессуар, посетив наши розничные
						точки (По городу Алматы: Mega 1 - островок "Casio G-Factory",
						магазин" G-Square" на Муканова 70; по городу Астана: ТРЦ Mega Silk
						Way - островок "Casio G-Factrory").
					</p>
				),
			},
			{
				title: "Есть ли ваш магазин на Kaspi.kz?",
				content: (
					<p>
						Да, наш магазин на Kaspi.kz называется{" "}
						<a
							href="https://kaspi.kz/shop/info/merchant/casio/address-tab/?merchantId=Casio"
							target="_blank"
						>
							CASIO G-Factory
						</a>
						, где представлен полный ассортимент наших товаров с возможностью
						оформления рассрочки на 3/6/12/24 месяца (в зависимости от
						актуального периода рассрочки на данный момент), а также оплаты
						через Kaspi Red.
					</p>
				),
			},
		];

    return (
        <>
            <Helmet>
                <title>Часто задаваемые вопросы</title>
            </Helmet>
            <PageHeader
                title="Часто задаваемые вопросы"
                breadcrumbs={[
                    {
                        link: '/',
                        text: 'Часто задаваемые вопросы'
                    }
                ]}
            />
            <DefaultPage>
                <Accords data={data} active={0} />
            </DefaultPage>
        </>
    )
}

export default FaqPage