import DefaultPage from "../components/defaultPage";
import PageHeader from "../components/pageHeader";
import TextBlock from "../components/textBlock";
import { Helmet } from "react-helmet";

const ServicePage = () => {
  return (
    <>
      <Helmet>
        <title>Сервисный центр</title>
      </Helmet>
      <PageHeader
        title="Сервисный центр"
        breadcrumbs={[
          {
            link: "/",
            text: "Сервисный центр",
          },
        ]}
      />
      <DefaultPage>
        <TextBlock>
          <>
            <p>
              <b>«G-Service»</b>  - официальный сервисный центр по ремонту и
              обслуживанию часов японских брендов CASIO и ORIENT.
            </p>
            <p>
              <b>Адрес:</b> г. Алматы, ул. Муканова 70 (Магазин часов G Square)
            </p>
            <p>
              <b>График работы:</b> <br />
              ПН – ПТ | 10:00 – 18:00 <br />
              СБ | 10:00 – 14:00 <br />
              ВС | Выходной
            </p>
            <p>
              <b>Контакты:</b>
            </p>
            <ul>
              <li>
                <a href="tel:+77071116466">
                  +7 (707) 111 64 66 (Мобильный, WhatsApp)
                </a>
              </li>
            </ul>
            <p>
              Сервисный центр проводит ремонт часов в строгом соответствии с
              мировыми стандартами с использованием качественного оборудования,
              технологий и запасных частей от производителя. Обращаясь в наш
              сервисный центр, вы можете быть уверены, что с вашими часами будет
              работать высококвалифицированный мастер, прошедший обучение,
              подтвержденное японскими брендами. Он не только отремонтирует ваши
              часы, но и расскажет об их особенностях и подскажет как продлить
              их жизнь.
            </p>
            <p>Условия гарантийного обслуживания:</p>
            <p>
              В проведении гарантийного ремонта может быть отказано в случае,
              если:
            </p>
            <ul>
              <li>
                Часы имеют следы вскрытия, проводившегося НЕ сертифицированным
                сервисным центром (далее СЦ) либо иными лицами, не являющимися
                представителями авторизированного СЦ Casio.
              </li>
              <li>
                Часы имеют следы использования не по назначению (любая из
                деталей часов имеет следы чрезмерного физического воздействия -
                следы ударов, вмятины, трещины, сколы на стекле, разрыв или
                деформирование браслета, ремня, их точек крепления и т.д.).
              </li>
              <li>
                Часы имеют следы контакта с химически активными веществами -
                кислотами, растворителями и т. п.
              </li>
              <li>
                Часы вышли из строя из-за несоблюдения правил эксплуатации.
              </li>
              <li>Отсутствует или повреждена фирменная упаковка изделия.</li>
            </ul>
            <p>Гарантийные обязательства не распространяются на:</p>
            <ul>
              <li>Ремешок или браслет часов.</li>
              <li>Покрытие корпуса часов.</li>
              <li>Механизм застегивания браслета или ремня.</li>
              <li>
                Крепление камней, наклеек и прочих элементов декорирования.
              </li>
            </ul>
          </>
        </TextBlock>
      </DefaultPage>
    </>
  );
};

export default ServicePage;
