import Header from "../../layouts/header";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "../../layouts/footer";
import AppRouter from "../../routes";
import { useEffect } from "react";
import CartController from "../../controllers/cartController";
import AuthController from "../../controllers/authController";
import AuthPopup from "../authPopup";
import EditPopup from "../editPopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      AuthController.checkAuth();
    }

    if (localStorage.getItem("cart")) {
      const added = JSON.parse(localStorage.getItem("cart"));
      CartController.setCart(added);
    }

    window.addEventListener("storage", (e) => {
      const { newValue, key } = e;

      if (key === "cart")
        CartController.setCart(newValue ? JSON.parse(newValue) : null, false);
    });

    if (!("path" in Event.prototype))
      Object.defineProperty(Event.prototype, "path", {
        get: function () {
          var path = [];
          var currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }
          if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
            path.push(document);
          if (path.indexOf(window) === -1) path.push(window);
          return path;
        },
      });
  });
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <Header />
        <AppRouter />
        <Footer />
        <AuthPopup />
        <EditPopup />
        <ToastContainer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
