import c from './styles.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { COORDS } from '../../config'

const ContactsMap = () => {
    const defaultState = {
        center: COORDS,
        zoom: 17,
    }
    return (
        <div className={c.map}>
            <YMaps>
                <Map defaultState={defaultState} className={c.inner}>
                    <Placemark geometry={COORDS} />
                </Map>
            </YMaps>
        </div>
    )
}

export default ContactsMap