import { type } from "os";
import cosmic from "../http/cosmic";

export default class CosmicService {
	static async getPosts(settings) {
		try {
			const { page, limit } = settings;

			const posts = await cosmic.objects
				.find({ type: "posts" })
				.props("slug,title,metadata")
				// .limit(limit ?? 7)
				// .skip(page ?? 1)
				.depth(1);

			console.log("Posts from cosmic:", posts);
			return posts.objects;
		} catch (error) {
			console.log("Error getting posts from Cosmic: ", error);
			throw new Error(error);
		}
	}

	static async getPostBySlug(slug) {
		try {
			const post = await cosmic.objects
				.findOne({
					type: "posts",
					slug,
				})
				.props("slug,title,metadata")
				.depth(1);

				return post.object;
		} catch (error) {
			console.log("Error getting posts from Cosmic: ", error);
			throw new Error(error);
		}
	}
}
