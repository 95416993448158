import c from "./styles.module.scss";
import Container from "../container";

import web_1 from "../../assets/images/banners/web_1.png";
import web_2 from "../../assets/images/banners/web_2.png";
import web_3 from "../../assets/images/banners/web_3.png";
import web_4 from "../../assets/images/banners/web_4.png";
import web_5 from "../../assets/images/banners/web_5.png";
import web_6 from "../../assets/images/banners/web_6.png";
import mobile_1 from "../../assets/images/banners/mobile_1.png";
import mobile_2 from "../../assets/images/banners/mobile_2.png";
import mobile_3 from "../../assets/images/banners/mobile_3.png";
import mobile_4 from "../../assets/images/banners/mobile_4.png";
import mobile_5 from "../../assets/images/banners/mobile_5.png";
import mobile_6 from "../../assets/images/banners/mobile_6.png";
import april_banner from "../../assets/images/banners/april_banner_2.png";
import april_banner_mobile from "../../assets/images/banners/april_banner_mobile.png";
import fifty_casio_desktop from "../../assets/images/banners/fifty_casio_desktop.png";
import fifty_casio_mobile from "../../assets/images/banners/fifty_casio_mobile.png";
// import slide1 from "../../assets/images/slide1.webp";
// import slide2 from "../../assets/images/slide2.webp";
// import slide3 from "../../assets/images/slide3.webp";
import { useState } from "react";
import Button from "../UI/button";
import { NavLink } from "react-router-dom";
import Tag from "../UI/tag";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

import { useRef } from "react";
// import discountBanner from "../../assets/images/banner.png";
// import mobileBanner2 from "../../assets/images/mobileBanner2.png";
import { useEffect } from "react";


const Slide = ({ data }) => {
  
  const {
    theme = "light",
    tag,
    title,
    text,
    btn,
    link,
    image,
    banner = false,
  } = data;
  const options = {
    classes: [c.slide, theme],
    inner: "",
    image: "",
    node: "div",
  };

  if (image && !banner) {
    options.image = (
      <div
        className={c.image}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    );
  }
  if (image && banner) {
    options.image = (
      <div
        className={c.banner}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    );
  }

  options.inner = (
    <>
      {tag ? <Tag text={tag} size="small" /> : null}
      {title ? <div className={c.title}>{title}</div> : null}
      {text ? <div className={c.text}>{text}</div> : null}
      {btn ? <div className={c.btn}>{btn}</div> : null}
    </>
  );

  if (link) {
    return (
      <NavLink to={link} end className={options.classes.join(" ")}>
        {options.image}
        <div className={c.content}>{options.inner}</div>
      </NavLink>
    );
  } else {
    return (
      <div className={options.classes.join(" ")}>
        {options.image}
        <div className={c.content}>{options.inner}</div>
      </div>
    );
  }
};

const MainSlider = () => {
  	const prevBtn = useRef(null);
		const nextBtn = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [data] = useState([
    {
      theme: "dark",
      tag: "",
      title: "",
      // text: "Новый Вадим Савельев лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,

      link: "/products",
      image: !isMobile ? fifty_casio_desktop : fifty_casio_mobile,
    },
    {
      theme: "dark",
      tag: "Лимитированное издание",
      title: "G-Shock",
      // text: "Новый Вадим Савельев лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A232587",
      image: !isMobile ? web_2 : mobile_2,
    },

    {
      theme: "dark",
      // tag: 'Лимитированное издание',
      tag: "Новинка",
      title: "General",
      // text: "Новый Вадим заместо старого",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A230981",
      image: !isMobile ? web_1 : mobile_1,
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Pro-Trek",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A260229",
      image: !isMobile ? web_3 : mobile_3,
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Sheen",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A260648",
      image: !isMobile ? web_4 : mobile_4,
    },

    {
      theme: "dark",
      tag: "Новинка",
      // tag: 'Лимитированное издание',
      title: "Baby-G",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A233297",
      image: !isMobile ? web_5 : mobile_5,
    },
    {
      theme: "dark",
      tag: "Новинка",
      title: "Edifice",
      // text: "Новая лимитированная коллекция",
      btn: <Button text="Подробнее" afterIcon="arrow-right" />,
      link: "/products?filters=227106%3A240396",
      image: !isMobile ? web_6 : mobile_6,
    },
  ]);
  	
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  	useEffect(() => {
		if (prevBtn.current && nextBtn.current) {
			// Необходимо, чтобы элементы были доступны
		}
	}, [prevBtn, nextBtn]);
  return (
		<section className={c.section}>
			<Container>
				<div className={c.inner}>
					<div className={[c.sliderNav, "dark"].join(" ")}>
						<Button
							ref={prevBtn}
							btnStyle="blur"
							icon="arrow-left"
							size="smaller"
						/>
						<Button
							ref={nextBtn}
							btnStyle="blur"
							icon="arrow-right"
							size="smaller"
						/>
					</div>
					<Swiper
						autoplay={{ delay: 5000, disableOnInteraction: false }}
						modules={[Navigation, Autoplay]}
						spaceBetween={40}
						navigation={{
							prevEl: prevBtn.current,
							nextEl: nextBtn.current,
						
						}}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = prevBtn.current;
							swiper.params.navigation.nextEl = nextBtn.current;
						}}
					>
						{/* <SwiperSlide>
								<BlackFridayBanner />
							</SwiperSlide> */}
						{data.map((item, index) => (
							<SwiperSlide key={index}>
								<Slide data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</Container>
		</section>
	);
};

export default MainSlider;
