import React, { useEffect, useState } from "react";
import c from "./styles.module.scss";
import CosmicController from "../../controllers/cosmicController";
import Container from "../container";
import PostItem from "../postItem";
import Spinner from "../spinner"; 

const limit = 7;

const BlogFeed = () => {
	const [posts, setPosts] = useState([]);
	const [page, setPage] = useState(1);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false); // состояние загрузки

	const getPosts = async () => {
		setLoading(true); // Устанавливаем загрузку в true
		try {
			const { data } = await CosmicController.getPosts(limit, page);
			setPosts(data);
		} catch (error) {
			setError("При подгрузке постов произошла ошибка");
		} finally {
			setLoading(false); // Завершаем загрузку
		}
	};

	useEffect(() => {
		getPosts();
	}, [page]); // Добавляем зависимость от страницы

	return (
		<section className={c.blogFeed}>
			<Container>
				<div className={c.feedInner}>
					{/* Если есть ошибка, отображаем сообщение */}
					{error && <h2>{error}</h2>}

					{/* Если идет загрузка, показываем спиннер */}
					{loading && <Spinner />}

					{/* Если посты загружены и нет ошибок, отображаем их */}
					{!loading &&
						!error &&
						posts &&
						posts.map((post) => <PostItem key={post.slug} post={post} />)}
				</div>
			</Container>
		</section>
	);
};

export default BlogFeed;
