const defaultState = {
  added: [],
  products: {},
  totalQuantity: 0,
  purePrice: 0,
  totalPrice: 0,
  discount: 0,
  promocode: {
    name: "",
    discountPercentage: 0,
  },
  formData: {
    name: "",
    phone: "",
    address: "",
    comment: "",
    agree: "",
  },
  formErrors: {
    name: "",
    phone: "",
    agree: "",
    address: "",
  },
  formError: "",
};

const SET_ADDED = "SET_ADDED";
const SET_PRODUCTS = "SET_PRODUCTS";
const SET_QUANTITY = "SET_QUANTITY";
const SET_PRICE = "SET_PRICE";
const SET_PURE_PRICE = "SET_PURE_PRICE";
const SET_DISCOUNT = "SET_DISCOUNT";
const SET_PROMOCODE = "SET_PROMOCODE";
const SET_FORM_DATA = "SET_FORM_DATA";
const REMOVE_FORM_DATA = "REMOVE_FORM_DATA";
const SET_FORM_IS_CORRECT = "SET_FORM_IS_CORRECT";
const SET_FORM_ERRORS = "SET_FORM_ERRORS";
const SET_FORM_ERROR = "SET_FORM_ERROR";

const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ADDED:
      return { ...state, added: action.payload };
    case SET_PRODUCTS:
      return { ...state, products: action.payload };
    case SET_QUANTITY:
      return { ...state, totalQuantity: action.payload };
    case SET_PRICE:
      return { ...state, totalPrice: action.payload };
    case SET_PURE_PRICE:
      return { ...state, purePrice: action.payload };
    case SET_DISCOUNT:
      return { ...state, discount: action.payload };
    case SET_PROMOCODE:
      return {
        ...state,
        promocode: {
          name: action.payload.name,
          discountPercentage: action.payload.discountPercentage,
        },
      };
    case SET_FORM_DATA:
      return { ...state, formData: action.payload };
    case REMOVE_FORM_DATA:
      return { ...state, formData: {} };
    case SET_FORM_IS_CORRECT:
      return { ...state, formIsCorrect: action.payload };
    case SET_FORM_ERRORS:
      return { ...state, formErrors: action.payload };
    case SET_FORM_ERROR:
      return { ...state, formError: action.payload };

    default:
      return state;
  }
};

const setAddedAction = (payload) => ({ type: SET_ADDED, payload });
const setProductsAction = (payload) => ({ type: SET_PRODUCTS, payload });
const setQuantityAction = (payload) => ({ type: SET_QUANTITY, payload });
const setPriceAction = (payload) => ({ type: SET_PRICE, payload });
const setPurePriceAction = (payload) => ({ type: SET_PURE_PRICE, payload });
const setDiscountAction = (payload) => ({ type: SET_DISCOUNT, payload });
const setPromocodeAction = (payload) => ({ type: SET_PROMOCODE, payload });
const setFormDataAction = (payload) => ({ type: SET_FORM_DATA, payload });
const removeFormDataAction = (payload) => ({ type: REMOVE_FORM_DATA, payload });
const setFormIsCorrectAction = (payload) => ({
  type: SET_FORM_IS_CORRECT,
  payload,
});
const setFormErrorsAction = (payload) => ({
  type: SET_FORM_ERRORS,
  payload,
});
const setFormErrorAction = (payload) => ({
  type: SET_FORM_ERROR,
  payload,
});

export {
  cartReducer,
  setAddedAction,
  setProductsAction,
  setQuantityAction,
  setPriceAction,
  setPurePriceAction,
  setDiscountAction,
  setPromocodeAction,
  setFormDataAction,
  removeFormDataAction,
  setFormIsCorrectAction,
  setFormErrorsAction,
  setFormErrorAction,
};
