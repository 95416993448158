import PageHeader from "../components/pageHeader"
import CartInfo from "../components/cartInfo"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"

const CartPage = () => {
    const {totalQuantity} = useSelector(store => store.cart)

    return (
        <>
            <Helmet>
                <title>{totalQuantity ? `Моя корзина (${totalQuantity})` : `Моя корзина`}</title>
            </Helmet>
            <PageHeader
                title="Корзина"
                breadcrumbs={[
                    {
                        text: 'Корзина',
                        link: '/cart'
                    }
                ]}
            />
            <CartInfo />
        </>
    )
}

export default CartPage