const MobileNav = [
  {
    icon: "home",
    url: "/",
    text: "Главная",
    type: "text",
  },
  {
    icon: "inbox",
    url: "/products",
    text: "Каталог",
    type: "text",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=225418%3A226525%2C225536",
    text: "Мужские часы",
    type: "text",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=225418%3A225421%2C226525",
    text: "Женские часы",
    type: "text",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A230981",
    text: "GENERAL",
    type: "text",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A232587",
    path: "/g-shock.svg",
    type: "svg",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A233297",
    path: "/baby-g.svg",
    type: "svg",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A240396",
    path: "/edifice.svg",
    type: "svg",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A260229",
    path: "/protrek.svg",
    type: "svg",
  },
  {
    icon: "arrow-right",
    url: "/products?filters=227106%3A260648",
    path: "/sheen.svg",
    type: "svg",
  },
  {
    icon: "box",
    url: "/delivery",
    text: "Доставка",
    type: "text",
  },
  {
    icon: "option",
    url: "/service",
    text: "Сервис центр",
    type: "text",
  },
  {
    icon: "help",
    url: "/faq",
    text: "FAQ",
    type: "text",
  },
  {
    icon: "phone",
    url: "/contact",
    text: "Контакты",
    type: "text",
  },
];

export default MobileNav;
