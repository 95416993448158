import c from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import Quantity from '../UI/quantity'
import { priceFormate } from '../../utils/price'
import Hoverable from '../hoverable'
import { useSelector } from 'react-redux'
import CartController from '../../controllers/cartController'
import EmptyBlock from '../emptyBlock'
import { API_URL } from '../../http'

const CartProductsItem = ({ data }) => {
    const { id, name, quantity, price, slug, image } = data
    let timer

    return (
        <div className={c.item}>
            <div className={c.info}>
                <NavLink to={'/products/' + id} end className={c.name}>
                    <div className={c.image}>
                        {image ? <img src={API_URL + image} alt={name} /> : <span className={[c.icon, 'icon-image'].join(' ')}></span>}
                    </div>
                    <div className={c.text}>
                        <div className={c.title}>{name}</div>
                        <div className={c.subtitle}>Артикул: {slug}</div>
                    </div>
                </NavLink>
                <div className={c.quantity}>
                    <Quantity
                        size="smaller"
                        value={quantity}
                        onChange={quantity => {
                            if(timer) {
                                timer = clearTimeout(timer)
                            }

                            timer = setTimeout(() => {
                                CartController.setQuantity(id, quantity)
                            }, 500)
                        }}
                    />
                </div>
                <div className={c.price}>
                    <div className={c.title}>{priceFormate(price)}</div>
                    <div className={c.subtitle}>Стоимость</div>
                </div>
                <Hoverable>
                    <div
                        className={[c.removeItem, 'icon-close'].join(' ')}
                        onClick={() => {
                            CartController.removeItem(id)
                        }}
                    ></div>
                </Hoverable>
            </div>
        </div>
    )
}

const CartProducts = () => {
    const { added, products } = useSelector(store => store.cart)
    let data = []

    for (let item of added) {
        const { id, quantity } = item

        if (products[id]) {
            const product = products[id]
            data.push({ ...product, quantity, price: product.price * quantity })
        }
    }

    return (
        <div className={c.products}>
            <div className={c.body}>
                {
                    data.length ?
                        data.map(item => <CartProductsItem key={item.id} data={item} />)
                        : <EmptyBlock icon="error" text="Нет товаров" padding={true} />
                }
            </div>
        </div>
    )
}

export default CartProducts