import c from './styles.module.scss'
import UserWidget from '../userWidget'
import CartBtn from '../cartBtn'

const HeaderBtns = () => {
    return (
        <div className={c.wrapper}>
            <UserWidget />
            <CartBtn />
        </div>
    )
}

export default HeaderBtns