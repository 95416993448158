import c from './styles.module.scss'
import Container from '../container'
import MobileNav from '../../routes/mobileNav'
import { NavLink } from 'react-router-dom'
import { PHONE, EMAIL, ADDRESS, SOCIAL } from "../../config"
import Button from '../UI/button'
import { setPopupAction } from '../../store/authReducer'
import { useSelector, useDispatch } from 'react-redux'
import UserWidgetMobile from '../userWidgetMobile'
import { dispatchStore } from '../../store'
import { setMenuAction } from '../../store/menuReducer'
import SearchField from '../searchField'

const CloseMenu = () => {
    dispatchStore(setMenuAction(false))
}

const MobileMenu = () => {
    const dispatch = useDispatch()
    const {isAuth} = useSelector(store => store.auth)
    const {show} = useSelector(store => store.menu)

    return (
			<div className={[c.mobileMenu, show ? c.show : null].join(" ")}>
				<Container>
					<div
						className={c.blackBoard}
						onClick={() => {
							dispatch(setMenuAction(false));
						}}
					></div>
					<div className={c.search}>
						<SearchField inputStyle="blur" size="larger" />
					</div>
					<div className={c.inner}>
						<div className={c.col}>
							{isAuth ? (
								<div className={c.block}>
									<UserWidgetMobile />
								</div>
							) : (
								<div className={c.authBtn}>
									<Button
										full={true}
										text="Авторизация"
										size="larger"
										onClick={() => {
											dispatch(setPopupAction(true));
										}}
									/>
								</div>
							)}
							<div className={c.block}>
								<div className={c.title}>Навигация</div>
								<nav className={c.nav}>
									<ul>
										{MobileNav.map((item, i) => (
											<li
												key={item.url}
												onClick={() => {
													sessionStorage.setItem("gf_page", 1);
												}}
											>
												<NavLink
													to={item.url}
													end
													className={({ isActive }) =>
														isActive ? c.activeLink : null
													}
												>
													<div
														className={[c.icon, "icon-" + item.icon].join(" ")}
													></div>
													<div className={c.text}>
														{item.type === "text" ? (
															item.text
														) : (
															<img src={item.path} alt={item.url} />
														)}
													</div>
												</NavLink>
											</li>
										))}
									</ul>
								</nav>
							</div>
						</div>

						<div className={c.col}>
							<div className={[c.block, c.contacts].join(" ")}>
								<div className={c.title}>Номер телефона</div>
								{PHONE.map((item, index) => (
									<a key={index} className={c.text} href={`tel:+${item.clean}`}>
										{item.formatted}
									</a>
								))}
							</div>
							<div className={[c.block, c.contacts].join(" ")}>
								<div className={c.title}>Email</div>
								<a className={c.text} href={`mailto:${EMAIL}`}>
									{EMAIL}
								</a>
							</div>
							<div className={[c.block, c.contacts].join(" ")}>
								<div className={c.title}>Адрес</div>
								<div className={c.text}>{ADDRESS}</div>
							</div>
							<div className={[c.block, c.contacts].join(" ")}>
								<div className={c.title}>Соц. сети</div>
								<div className={c.links}>
									<div className={c.line}>
										{SOCIAL.length
											? SOCIAL.map((item, i) => {
													return (
														<a
															key={i}
															className={c.link}
															href={item.link}
															targer="_blank"
														>
															{item.name}
														</a>
													);
											  })
											: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		);
}

export default MobileMenu
export { CloseMenu }