export function filtersString(str) {
    const filters = {}

    if(str) {
        const explodedFilters = str.split(';')
    
        for(let filter of explodedFilters) {
            let item = filter.split(':')
            let values = item[1].split(',')
            filters[item[0]] = values.map(value => Number(value))
        }
    }

    return filters
}

export function toFiltersString(filters) {
    const filtersString = []

    if(Object.keys(filters).length) {
        for(let key in filters) {
            const values = filters[key]
            filtersString.push(`${key}:${values.join(',')}`)
        }
    }

    return filtersString.join(';')
}