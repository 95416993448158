import c from "./styles.module.scss";
import PagesMenu from "../../routes/pagesMenu";
import { NavLink } from "react-router-dom";

const PagesSidebar = () => {
  return (
    <aside className={c.aside}>
      <nav>
        <ul>
          {PagesMenu.map((item, i) => (
            <li key={i}>
              <NavLink
                to={item.url}
                end
                className={({ isActive }) => (isActive ? c.activeLink : null)}
              >
                {item.type === "text" ? (
                  item.text
                ) : (
                  <img src={item.path} alt={item.url} />
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default PagesSidebar;
