import PageHeader from "../components/pageHeader"
import ProductsArchive from "../components/productsArchive"
import MetaHelmet from "../components/meta/metaHelmet"

import { useLocation } from "react-router-dom";
const ProductsPage = () => {
    let optionName = "";
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const filters = searchParams.get("filters");
    

    if(filters === "225418:226525,225536"){
        optionName = "menWatches"
    } else if(filters === "225418:225421,226525") {
        optionName = "womenWatches"
    } else if(filters === "227106:230981") {
        optionName = "general"
    } else if(filters === "227106:232587") {
        optionName = "g-shock"
    } else if(filters === "227106:233297") {
        optionName = "baby-g"
    } else if(filters === "227106:240396") {
        optionName = "edifice"
    } else if(filters === "227106:260229") {
        optionName = "pro-trek"
    } else if(filters === "227106:260648") {
        optionName = "sheen"
    } else {
        optionName = "catalog"
    }

    console.log(optionName)
    return (
        <>
            <MetaHelmet option={optionName} />
            <PageHeader
                title="Каталог"
                breadcrumbs={[
                    {
                        link: '/products',
                        text: 'Каталог'
                    }
                ]}
            />
            <ProductsArchive optionName={optionName}/>
        </>
    )
}

export default ProductsPage