import c from './styles.module.scss'
import Container from '../container'
import Button from '../UI/button'

const NotFound = () => {
    return (
        <section className={c.section}>
            <Container>
                <div className={c.inner}>  
                    <h1 className={c.title}>Страница не найдена</h1>
                    <p>Страница, на которую вы пытаетесь попасть, не существует или была удалена.</p>
                    <div className={c.btns}>
                        <Button link="/" text="На главную" size="larger"/>
                        <Button link="/products" btnStyle="grey" text="В каталог" size="larger"/>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default NotFound