import { useState } from "react";
import {
	emailValidation,
	nameValidation,
	passwordValidation,
	confirmPasswordValidation,
	phoneValidation,
	addressValidation,
} from "../../../utils/validation";
import c from "./styles.module.scss";
import { v4 } from "uuid";
import Hoverable from "../../hoverable";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const Input = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleTogglePassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const options = {
		classes: [c.input],
		wrapperClasses: [c.wrapper],
		onChange: null,
		id: v4(),
		readOnly: false,
	};
	const {
		size = "normal",
		value = "",
		onChange = null,
		onBlur = null,
		placeholder = "",
		type = "text",
		disabled = false,
		icon = "",
		btn = null,
		validation = null,
		description = "",
		readOnly = false,
		required = false,
		inputStyle = "primary",
		label = "",
	} = props;
	const [error, setError] = useState(props.error || false);
	const [errorMessage, setErrorMessage] = useState(props.errorMessage || "");
	const iconElement = icon ? (
		<span className={[c.icon, c["__" + size], "icon-" + icon].join(" ")}></span>
	) : null;

	options.classes.push(c[size]);
	options.classes.push(c[inputStyle]);

	options.readOnly = readOnly;
	options.required = required;
	options.label = label;

	if (error) {
		options.classes.push(c.error);
	}

	if (validation) {
		const { type, password, onChange } = validation;

		if (type === "email") {
			options.onChange = (e) => {
				emailValidation(e.target.value, setError, setErrorMessage, onChange);
			};
		} else if (type === "password") {
			options.onChange = (e) => {
				passwordValidation(e.target.value, setError, setErrorMessage, onChange);
			};
		} else if (type === "confirmPassword") {
			options.onChange = (e) => {
				confirmPasswordValidation(
					e.target.value,
					password,
					setError,
					setErrorMessage,
					onChange
				);
			};
		} else if (type === "name") {
			options.onChange = (e) => {
				nameValidation(e.target.value, setError, setErrorMessage, onChange);
			};
		} else if (type === "phone") {
			options.onChange = (e) => {
				phoneValidation(e.target.value, setError, setErrorMessage, onChange);
			};
		} else if (type === "address") {
			options.onChange = (e) => {
				addressValidation(e.target.value, setError, setErrorMessage, onChange);
			};
		}
	}

	return (
		<div className={c.formGroup}>
			{options.label ? (
				<label className={c.label} htmlFor={options.id}>
					{options.label}
					{options.required ? <span className={c.mark}>*</span> : null}
				</label>
			) : null}
			<div className={options.wrapperClasses.join(" ")}>
				{iconElement}
				<Hoverable>
					<input
						type={showPassword ? "text" : type}
						placeholder={placeholder}
						value={value}
						onChange={(e) => {
							if (onChange) onChange(e);

							if (options.onChange) options.onChange(e);
						}}
						onBlur={onBlur}
						className={options.classes.join(" ")}
						disabled={disabled}
						id={options.id}
						readOnly={options.readOnly}
						required={options.required}
					/>
				</Hoverable>
				{type === "password" && (
					<span className={c.showPasswordIcon} onClick={handleTogglePassword}>
						{showPassword ? <IoMdEyeOff /> : <IoMdEye />}
					</span>
				)}

				{btn ? <div className={c.btn}>{btn}</div> : null}
			</div>

			{error && errorMessage ? (
				<div className={[c.errorMessage, "icon-error"].join(" ")}>
					{errorMessage}
				</div>
			) : null}
			{description ? (
				<div className={[c.description, "icon-warning"].join(" ")}>
					{description}
				</div>
			) : null}
		</div>
	);
};

export default Input;
