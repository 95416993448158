import FiltersSevice from "../services/filtersService"

export default class FiltersController {
    static async getAll(products, applied, available) {
        const result = {
            success: false,
            message: ''
        }

        try {
            const response = await FiltersSevice.getAll(products, applied, available)
            
            result.data = response.data
            result.success = true
            return result
        } catch (e) {
            result.message = 'Ошибка при получении данных'
            return result
        }
    }
}