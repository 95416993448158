import c from "./styles.module.scss";
import Container from "../../components/container";
import Logo from "../../components/logo";
import TopHeaderMenu from "../../routes/topHeaderMenu";
import { NavLink } from "react-router-dom";
import { PHONE, EMAIL, SOCIAL } from "../../config";


const Footer = () => {
  return (
    <footer className={c.footer}>
      <Container>
        <div className={c.topFooter}>
          <div className={c.inner}>
            <div className={c.about}>
              <Logo />
              <p>
                G-Factory – официальный интернет-магазин японских наручных часов
                CASIO в Казахстане.
              </p>
              <nav>
                <ul>
                  {TopHeaderMenu.map((item, i) => (
                    <li key={i}>
                      <NavLink
                        to={item.url}
                        end
                        className={({ isActive }) =>
                          isActive ? c.activeLink : null
                        }
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className={c.contacts}>
              <a href={`tel:+${PHONE[0].clean}`} className={c.phone}>
                {PHONE[0].formatted}
              </a>
              <a href={`mailto:${EMAIL}`} className={c.email}>
                {EMAIL}
              </a>
            </div>
          </div>
        </div>
        <div className={c.bottomFooter}>
          <div className={c.inner}>
            <div className={c.copyright}>
              © 2024 Любое использование контента без письменного разрешения
              запрещено
            </div>
            <div className={c.social}>
              {SOCIAL.length
                ? SOCIAL.map((item, i) => {
                    return (
                     <a href={item.link} key={i}>
                      <item.Icon />
                     </a>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
