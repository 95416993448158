import React, { useState, useRef } from "react";
import c from "./styles.module.scss";
import Container from "../container";
import { mainText } from "./constants";

const AdditionalText = () => {
  const [expanded, setExpanded] = useState(false);
  const textRef = useRef(null);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <section className={c.additional}>
      <Container>
        <div className={`${c.textWrapper} ${expanded ? c.expanded : ""}`}>
          <div
            ref={textRef}
            className={`${c.text}`}
            dangerouslySetInnerHTML={{ __html: mainText }}
          ></div>
          {!expanded && <div className={c.fadeOut}></div>}
        </div>
        <span onClick={toggleExpand} className={c.readMore}>
          {!expanded ? "Читать еще" : "Скрыть"}
        </span>
      </Container>
    </section>
  );
};

export default AdditionalText;
