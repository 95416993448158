import c from '../input/styles.module.scss'
import Hoverable from '../../hoverable'
import Dropdown from '../../dropdown'
import ClickOutside from '../../clickOutside'
import { useState, useEffect } from 'react'

const Select = (props) => {
    const {value = '', items = [], disabled = false, onChange = null, size = 'normal', inputStyle = 'primary'} = props
    const [text, setText] = useState('')
    const [show, setShow] = useState(false)

    useEffect(() => {
        for(let item of items) {
            if(item.value === value) {
                setText(item.text)
            }
        }
    }, [value])

    return (
        <Hoverable>
            <ClickOutside
                onClick={() => {
                    setShow(false)
                }}
            >
                <div className={c.wrapper}>
                    <div className={[c.afterIcon, 'icon-select'].join(' ')}></div>
                    <input
                        value={text}
                        className={[c.input, c[size], c[inputStyle]].join(' ')}
                        disabled={disabled}
                        onClick={() => {
                            setShow(!show)
                        }}
                        readOnly
                    />
                    <div className={[c.dropdown, show ? c.show : null].join(' ')}>
                        <Dropdown
                            items={
                                items.map(item => {
                                    return {
                                        active: item.value === value,
                                        text: item.text,
                                        onClick: () => {
                                            onChange(item.value)
                                            setShow(false)
                                        }
                                    }
                                })
                            }
                        />
                    </div>
                </div>
            </ClickOutside>
        </Hoverable>
    )
}

export default Select