import { COLORS, RAFFLEDATE } from "../config"

export function getColors() {
    const date = new Date()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()

    if(RAFFLEDATE === `${day}.${month}`) {
        const keys = Object.keys(COLORS)
        const values = Object.values(COLORS).sort(() => Math.random() - 0.5)
        let final = {}

        for(let index in keys) {
            final[keys[index]] = values[index]
        }

        return final
    } else {
        return COLORS
    }
}