import c from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import { priceFormate } from '../../utils/price'
import HoverEffect from '../hoverEffect'
import { useSelector } from 'react-redux'
import CartDropdown from '../cartDropdown'
import { useState } from 'react'
import ClickOutside from '../clickOutside'

const CartBtn = () => {
    const cart = useSelector(store => store.cart)
    const [show, setShow] = useState(false)
    
    return (
        <ClickOutside onClick={() => setShow(false)}>
            <div
                className={c.cartBtn}
                onMouseEnter={() => {
                    setShow(true)
                }}
                onMouseLeave={() => {
                    setShow(false)
                }}
            >
                <HoverEffect>
                    <NavLink to="/cart" end className={c.cartBtnWrapper}>
                        <div className={[c.icon, 'icon-cart'].join(' ')} data-count={cart.totalQuantity}></div>
                        <div className={c.textWrapper}>
                            <div className={c.price}>{priceFormate(cart.totalPrice)}</div>
                            <div className={c.text}>Ваша корзина</div>
                        </div>
                    </NavLink>
                </HoverEffect>
                <div className={[c.dropdown, show ? c.show : null].join(' ')}>
                    <CartDropdown onClose={() => setShow(false)}/>
                </div>
            </div>
        </ClickOutside>
    )
}

export default CartBtn