
import Container from '../container'
import Product from '../product'
import Button from '../UI/button'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar } from 'swiper'
import { useRef, useState, useEffect } from 'react'
import ProductController from '../../controllers/productController'
import Spinner from '../spinner'
import EmptyBlock from '../emptyBlock'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import c from "./styles.module.scss";

const ProductsSlider = ({title = '', params = {withImage: true, available: 1, rand: true}}) => {
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const prevBtn = useRef(null)
    const nextBtn = useRef(null)
    const scrollbar = useRef()

    if(!params.limit) {
        params.limit = 8
    }

    console.log("Params: ", params);

    const fetchData = async () => {
        const response = await ProductController.getAll(params)

        if(response.success) {
            setProducts(response.data.rows)
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

	useEffect(() => {
		if (prevBtn.current && nextBtn.current) {
			// Необходимо, чтобы элементы были доступны
		}
	}, [prevBtn, nextBtn]);

    return (
			<section className={c.section}>
				<Container>
					{loading ? (
						<Spinner />
					) : products.length ? (
						<div className={c.inner}>
							<div className="h2">{title}</div>

							<div className={c.slider}>
								<div className={c.sliderControll}>
									<div className={c.arrows}>
										<Button
											ref={prevBtn}
											icon="arrow-left"
											size="smaller"
											btnStyle="grey"
										/>
										<Button
											ref={nextBtn}
											icon="arrow-right"
											size="smaller"
											btnStyle="grey"
										/>
									</div>
									<div className={c.scrollbar} ref={scrollbar}></div>
									<Button
										link="/products"
										text="Смотреть все"
										size="smaller"
										btnStyle="grey"
									/>
								</div>
								<Swiper
									modules={[Navigation, Scrollbar]}
									navigation={{
										prevEl: prevBtn.current,
										nextEl: nextBtn.current,
										
									}}
									onBeforeInit={(swiper) => {
										swiper.params.navigation.prevEl = prevBtn.current;
										swiper.params.navigation.nextEl = nextBtn.current;
										swiper.params.scrollbar.el = scrollbar.current;
									}}
									scrollbar={{
										el: scrollbar,
										dragClass: c.scrollbarDrag,
										horizontalClass: c.scrollbarLine,
										draggable: true,
									}}
									slidesPerView={2} 
									
									spaceBetween={30}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1200: {
											slidesPerView: 4,
										},
									}}
								>
									{products
										.filter((item) => item.quantity > 0)
										.map((item) => (
											<SwiperSlide key={item.id}>
												<Product data={item} />
											</SwiperSlide>
										))}
								</Swiper>
							</div>
						</div>
					) : (
						<EmptyBlock icon="error" text="Ничего не найдено" />
					)}
				</Container>
			</section>
		);
}

export default ProductsSlider