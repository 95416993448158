import DefaultPage from "../components/defaultPage"
import PageHeader from "../components/pageHeader"
import MyOrders from "../components/myOrders"
import { Helmet } from "react-helmet"

const MyOrdersPage = () => {
    return (
        <>
            <Helmet>
                <title>Мои заказы</title>
            </Helmet>
            <PageHeader
                title="Мои заказы"
                breadcrumbs={[
                    {
                        link: '/',
                        text: 'Мои заказы'
                    }
                ]}
            />
            <DefaultPage>
                <MyOrders />
            </DefaultPage>
        </>
    )
}

export default MyOrdersPage