import c from "./styles.module.scss";
import Container from "../container";

const StatusBlock = ({ icon, title, text, btn }) => {
  return (
    <section className={c.section}>
      <Container>
        <div className={c.inner}>
          {icon ? (
            <div className={c.icon}>
              <div className={[c.iconInner, "icon-" + icon].join(" ")}></div>
            </div>
          ) : null}
          {title ? <h1 className={c.title}>{title}</h1> : null}
          {text ? <p className={c.text}>{text}</p> : null}
          {btn ? <div className={c.btn}>{btn}</div> : null}
        </div>
      </Container>
    </section>
  );
};

export default StatusBlock;
