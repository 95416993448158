import c from "./styles.module.scss";
import Hoverable from "../../hoverable";
import { forwardRef, useRef } from "react";
import { NavLink } from "react-router-dom";
import HoverEffect from "../../hoverEffect";

const Inner = (props) => {
  const {
    classes = [],
    inner = "",
    beforeIcon = "",
    afterIcon = "",
    icon = "",
  } = props;

  if (icon) {
    classes.push("icon-" + icon);
  }

  let before;
  let after;

  if (beforeIcon) {
    before = (
      <span className={[c.beforeIcon, "icon-" + beforeIcon].join(" ")}></span>
    );
  }

  if (afterIcon) {
    after = (
      <span className={[c.afterIcon, "icon-" + afterIcon].join(" ")}></span>
    );
  }

  const element = (
    <span className={classes.join(" ")}>
      {before}
      {inner}
      {after}
    </span>
  );

  return element;
};

const Button = forwardRef((props, ref) => {
  const btnRef = useRef();

  if (!ref) {
    ref = btnRef;
  }
  const options = {
    classes: [c.btn],
    inner: null,
    innerClasses: [],
    node: "button",
  };
  const {
    text = "",
    btnStyle = "primary",
    size = "normal",
    onClick = null,
    disabled = false,
    beforeIcon = "",
    afterIcon = "",
    icon = "",
    link = "",
    full = false,
    loading = false,
    rounded = false,
    color = "primary",
    externalLink = "",
  } = props;

  if (full) {
    options.classes.push(c.full);
  }

  if (rounded) {
    options.classes.push(c.rounded);
  }

  options.onClick = onClick;

  if (text) {
    options.innerClasses.push(c.text);
  }

  if (icon) {
    options.innerClasses.push(c.icon);
  }

  if (loading) {
    options.classes.push(c.loading);
  }

  options.inner = (
    <Inner
      classes={options.innerClasses}
      inner={text}
      beforeIcon={beforeIcon}
      afterIcon={afterIcon}
      icon={icon}
    />
  );
  options.classes.push(c[size]);
  options.classes.push(c[btnStyle]);

  const attrs = {
    ref: ref,
    className: options.classes.join(" "),
    onClick: options.onClick,
    disabled: disabled || loading ? true : false,
    color: color,
  };

  if (link) {
    attrs.to = link;
    attrs.end = true;

    return (
      <HoverEffect full={full}>
        <Hoverable>
          <NavLink {...attrs}>
            {loading ? <span className={c.spinner}></span> : null}
            {options.inner}
          </NavLink>
        </Hoverable>
      </HoverEffect>
    );
  } else if (externalLink) {
    attrs.href = externalLink;
    attrs.target = "_blank";

    return (
      <HoverEffect full={full}>
        <Hoverable>
          <a {...attrs}>
            {loading ? <span className={c.spinner}></span> : null}
            {options.inner}
          </a>
        </Hoverable>
      </HoverEffect>
    );
  } else {
    return (
      <HoverEffect full={full}>
        <Hoverable>
          <button {...attrs}>
            {loading ? <span className={c.spinner}></span> : null}
            {options.inner}
          </button>
        </Hoverable>
      </HoverEffect>
    );
  }
});

export default Button;
