import {
  HomePage,
  ProductPage,
  CartPage,
  ProductsPage,
  ContactPage,
  ServicePage,
  DeliveryPage,
  FaqPage,
  NotFoundPage,
  SuccessPage,
  MyOrdersPage,
  ErrorPage,
  SearchPage,
} from "../pages";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CloseMenu } from "../components/mobileMenu";
import OfferPage from "../pages/offerPage";
import BlogPage from "../pages/blogPage";
import PostPage from "../pages/postPage";


const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    CloseMenu();
  }, [location]);

  return (
		<Routes>
			<Route path="/" element={<HomePage />} />
			<Route path="/products" element={<ProductsPage />} />
			<Route path="/products/:id" element={<ProductPage />} />
			<Route path="/cart" element={<CartPage />} />
			<Route path="/contact" element={<ContactPage />} />
			<Route path="/service" element={<ServicePage />} />
			<Route path="/delivery" element={<DeliveryPage />} />
			<Route path="/faq" element={<FaqPage />} />
			<Route path="/success" element={<SuccessPage />} />
			<Route path="/error" element={<ErrorPage />} />
			<Route path="/orders" element={<MyOrdersPage />} />
			<Route path="/offer" element={<OfferPage />} />
			<Route path="/blog" element={<BlogPage />} />
			<Route path="/blog/:slug" element={<PostPage />} />

			<Route path="/search/:query" element={<SearchPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default AppRouter;
