import c from './styles.module.scss'
import CartBtnMobile from '../cartBtnMobile'

const HeaderBtnsMobile = () => {
    return (
        <div className={c.wrapper}>
            <CartBtnMobile />
        </div>
    )
}

export default HeaderBtnsMobile