import StatusBlock from "../components/statusBlock";
import Button from "../components/UI/button";
import { Helmet } from "react-helmet";
import JusanPaymentSystemService from "../services/jusanPaymentSystemService";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CartController from "../controllers/cartController";
import $api from "../http";
import c from "../components/cartSidebar/styles.module.scss";

const SuccessPage = () => {
  const [paymentParams, setPaymentParams] = useState("");
  const { totalPrice, products, added, totalQuantity, formData } = useSelector(
    (state) => state.cart
  );

  const getPaymentParams = async () => {
    const paymentParams = await JusanPaymentSystemService.getParams(totalPrice);

    console.log(paymentParams);

    setPaymentParams(paymentParams.data);
  };

  const buyInInstallment = async () => {
    const productIdsWithQuantities = added;
    const allProducts = productIdsWithQuantities.map((product) => ({
      id: String(product.id),
      name: products[Number(product.id)].name,
      price: Math.round(products[Number(product.id)].price),
      quantity: product.quantity,
    }));

    const redirectData = await $api.post("/api/bnpl/redirectLink", {
      billAmount: String(Math.round(totalPrice)),
      itemsQuantity: totalQuantity,
      products: allProducts,
      phoneNumber: formData.phone,
    });

    window.location.replace(redirectData.data.redirectLink);
  };

  const clearCart = async () => {
    await CartController.clearCart();
  };

  useEffect(() => {
    getPaymentParams();
  }, []);

  return (
    <>
      <Helmet>
        <title>Заявка отправлена</title>
      </Helmet>
      <StatusBlock
        icon="check-alt"
        title="Заявка отправлена"
        text="Наши менеджеры свяжутся с вами в ближайшее время."
        btn={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <a
              className={c.payOrder}
              href={`https://jpay.jysanbank.kz/ecom/api?${paymentParams}`}
              onClick={clearCart}
            >
              Оплатить заказ
            </a>
            <button
              className={c.installment}
              onClick={() => {
                buyInInstallment();
                clearCart();
              }}
            >
              Оплатить в рассрочку 0-0-4
            </button>
          </div>
        }
      />
    </>
  );
};

export default SuccessPage;
