import NotFound from "../components/notFound"
import { Helmet } from "react-helmet"

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>Страница не найдена</title>
            </Helmet>
            <NotFound />
        </>
    )
}

export default NotFoundPage