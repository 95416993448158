import c from './styles.module.scss'
import Input from '../input'
import { priceFormate } from '../../../utils/price'
import { useState } from 'react'
import { useRef } from 'react'
import ReactSlider from 'react-slider'

class ModReactSlider extends ReactSlider {
    setValue (newValue) {
        this.setState({
            value: newValue
        })
    }
}

const PriceSlider = ({min, max, value, onChange = null}) => {
    const slider = useRef()
    const [minValue, setMinValue] = useState(priceFormate(value[0]))
    const [maxValue, setMaxValue] = useState(priceFormate(value[1]))

    const setNewValue = (val, type) => {   
        let newValue = value    
        val = Number(val.replaceAll(/[^0-9]/gm, '')) || 0

        if(type === 'max') {
            newValue[1] = Math.min(max, Math.max(newValue[0], val))
        } else if (type === 'min') {
            newValue[0] = Math.max(min, Math.min(newValue[1], val))
        }

        slider.current.setValue(newValue)
        setMinValue(priceFormate(newValue[0]))
        setMaxValue(priceFormate(newValue[1]))
        onChange(newValue)
    }

    return (
        <div className={c.sliderWrapper}>
            <div className={c.slider}>
                <ModReactSlider
                    ref={slider}
                    className={c.sliderLine}
                    thumbClassName={c.sliderThumb}
                    trackClassName={c.sliderTrack}
                    defaultValue={value}
                    min={min}
                    max={max}
                    withTracks={true}
                    pearling
                    minDistance={1}
                    onChange={newValue => {
                        setMinValue(priceFormate(newValue[0]))
                        setMaxValue(priceFormate(newValue[1]))
                    }}
                    onAfterChange={newValue => {
                        onChange(newValue)
                    }}
                />
            </div>
            <div className={c.inputs}>
                <div className={c.input}>
                    <Input
                        value={minValue}
                        onChange={e => setMinValue(e.target.value)}
                        size="smaller"
                        onBlur={e => {
                            setNewValue(e.target.value, 'min')
                        }}
                    />
                </div>
                <div className={c.sep}>-</div>
                <div className={c.input}>
                    <Input
                        value={maxValue}
                        onChange={e => setMaxValue(e.target.value)}
                        size="smaller"
                        onBlur={e => {
                            setNewValue(e.target.value, 'max')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PriceSlider