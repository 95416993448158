import c from './styles.module.scss'
import Container from '../container'
import Breadcrumbs from '../breadcrumbs'

const PageHeader = ({title = '', afterTitle = '', after = null, breadcrumbs = []}) => {
    return (
        <div className={c.pageHeader}>
            <Container>
                <div className={c.inner}>
                    <div className={c.titleWrapper}>
                        <h1 className={[c.title, 'h3'].join(' ')}>{title}{afterTitle ? <span> {afterTitle}</span> : null}</h1>
                        { breadcrumbs ? <Breadcrumbs path={breadcrumbs}/> : null }
                    </div>
                    {after ? <div className={c.after}>{after}</div> : null}
                </div>
            </Container>
        </div>
    )
}

export default PageHeader