import c from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import Hoverable from '../../hoverable'

const Tag = (props) => {
    const {text = '', size = 'normal', tagStyle = 'primary', link, onClose = null} = props
    const options = {
        inner: <div className={c.text}>{text}</div>,
        classes: [c.tag, c[size], c[tagStyle]]
    }

    if(onClose) {
        options.inner = (
            <>
                {options.inner}
                <Hoverable><div className={[c.close, 'icon-close'].join(' ')} onClick={onClose}></div></Hoverable>
            </>
        )
    }
    
    if(link) {
        return <NavLink to={link} end className={options.classes.join(' ')}>{options.inner}</NavLink>
    } else {
        return <div className={options.classes.join(' ')}>{options.inner}</div>
    }
}

export default Tag