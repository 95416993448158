import c from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import AuthController from '../../controllers/authController'
import { setEditPopupAction } from '../../store/authReducer'
import { CloseMenu } from '../mobileMenu'
import Button from '../../components/UI/button'
import { NavLink } from 'react-router-dom'

const UserWidgetMobile = () => {
    const dispatch = useDispatch()
    const {user} = useSelector(store => store.auth)
    const {name, email} = user

    return (
        <div className={c.userBlock}>
            <div className={c.user}>
                <div className={c.name}>{name}</div>
                <div className={c.email}>{email}</div>
            </div>
            <nav>
                <ul>
                    <li>
                        <div
                            className={c.item}
                            onClick={() => {
                                dispatch(setEditPopupAction(true))
                            }}
                        >
                            <div className={[c.icon, 'icon-edit'].join(' ')}></div>
                            <div className={c.text}>Редактировать</div>
                        </div>
                    </li>
                    <li>
                        <NavLink end to="/orders" onClick={CloseMenu} className={c.item}>
                            <div className={[c.icon, 'icon-inbox'].join(' ')}></div>
                            <div className={c.text}>Мои заказы</div>
                        </NavLink>
                    </li>
                    <li>
                        <div
                            className={c.item}
                            onClick={() => {
                                AuthController.logout()
                            }}
                        >
                            <div className={[c.icon, 'icon-off'].join(' ')}></div>
                            <div className={c.text}>Выйти</div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default UserWidgetMobile